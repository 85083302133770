import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { saiki, api, loading, rendModal, rendTable, getCookie, submitForm } from "../modul";
import img_upload from '../bg_uppload-image.png';
import img_contoh from '../Taglokasi.png';

function App() {
    document.title = "Pindapatta";
    var typingTimer;
    var delay = 500;

    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "Tanggal",
                by: "DESC",
                idlocation: 0
            };
        }

        componentDidMount() {
            ReactDOM.render("Pindapata", document.getElementById('divHeaderLabel'));
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            clearTimeout(typingTimer);
            typingTimer = setTimeout(async () => {
                this.main();
            }, delay);
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => { this.main(); }, 100);
        }

        modalDelete(ini) {
            let dom = (
                <Fragment>
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <input type="hidden" name="token" value={getCookie("Token")} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </Fragment>
            );

            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });

            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "pindapata_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        async handleStatus(e) {
            let chk = e.target;
            let sql = await api("pindapata_change_status", { ID: chk.value, Status: chk.checked == true ? 1 : 0, token: getCookie("Token") });

            if (sql.status == "true") {
                setTimeout(() => {
                    this.main();
                }, 500);
            }
        }

        async main() {
            let param = {
                search: this.state.search,
                index: this.state.index,
                searchcount: this.state.searchcount,
                token: getCookie("Token"),
                sort: this.state.sort,
                by: this.state.by
            }

            let sql = await api("pindapata_browse", param);
            console.log(sql);

            let field = {
                thead: [
                    { cap: "", sort: "", type: "opsi", width: "120px" },
                    { cap: "Logo", sort: "Gambar", type: "img", width: "120px", align: "center" },
                    { cap: "Tanggal", sort: "Tanggal", width: "150px", type: "date" },
                    { cap: "Jam Broadcast", sort: "JamBroadcast", type: "str", width: "200px" },
                    { cap: "Jumlah Orang", sort: "JumlahOrang", type: "str", width: "150px" },
                    { cap: "Organisasi", sort: "NamaCampaign", type: "str", width: "250px" },
                    { cap: "Status", sort: "IsActive", type: "checkbox", width: "150px" },
                    { cap: "Alamat", sort: "Alamat", type: "str" }
                ],
                j: sql.ct
            }

            rendTable({
                dataset: { data: sql.data, field: field },
                div: "divView",
                table: { cls: "table  " },
                thead: { cls: "thead-dark" },
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                tbody: {
                    id: "tbodyDataBooking",
                    opsi: [
                        { type: "edit", event: (e) => rendForm(e) },
                        { type: "delete", event: (e) => this.modalDelete(e) },
                    ],
                    chkFn: (e) => this.handleStatus(e)
                },
                paggination: true
            });
        }

        render() {
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Pindapatta</h2>
                        <div className='row'>
                            <div className='col-md-9'>
                                <button className="btn btn-default" onClick={(e) => rendForm(e)}>+ Tambah</button>
                            </div>
                            <div className='col-md-3'>
                                <div className='input-icons'>
                                    <span className='material-icons icon-search'>search</span>
                                    <input className="form-control search-form" type="search" name="search" placeholder="Search" value={this.state.search} onChange={(e) => this.handleChange(e, "search")} required />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView"></div>
                    </div>
                    <nav id="paggination" style={{ paddingRight: "50px" }}></nav>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                Tanggal: saiki(),
                JamBroadcast: "",
                JumlahOrang: 0,
                Alamat: "",
                Url: "",
                Gambar: "",
                PesanBroadcast: "",
                Description: "",
                IDCampaign: "",
                IDLokasi: "asdasd",
                TagLokasi: "",
                changeImg: "no",
                isReqImg: true,
                Title: "Tambah Pindapatta",
                Organisasi: [],
                Lokasi: [],
            }
        }

        async componentDidMount() {
            ReactDOM.render(<div className='btn-kembali' onClick={() => App()}><spam className="material-icons">arrow_back</spam> Kembali</div>, document.getElementById('divHeaderLabel'));
            let sql = await api("pindapata_detail", { iddata: this.state.id, token: getCookie("Token") });
            this.setState({ Organisasi: sql.Organisasi, Lokasi: sql.Lokasi });
            if (this.state.id !== "") {
                this.setState({
                    Tanggal: sql.data.Tanggal,
                    JamBroadcast: sql.data.JamBroadcast,
                    JumlahOrang: sql.data.JumlahOrang,
                    PesanBroadcast: sql.data.PesanBroadcast,
                    Alamat: sql.data.Alamat,
                    Url: sql.data.Url,
                    TagLokasi: sql.data.TagLokasi,
                    Gambar: sql.data.Gambar,
                    IDCampaign: sql.data.IDCampaign,
                    IDLokasi: sql.data.IDlokasi,
                    Description: sql.data.Description ? sql.data.Description.replaceAll("<br>", '\n') : "",
                    isReqImg: false,
                    Title: "Edit Pindapatta",
                });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        handleCheck(e) {
            this.setState({ IsApproval: e.checked });
        }

        openFile(e) {
            document.getElementById("labelImg").innerHTML = '';
            let files = e.target.files;
            let file;
            for (let i = 0; i < files.length; i++) {
                let reader = new FileReader();
                file = files[i];
                reader.onload = (file) => {
                    document.getElementById("labelImg").innerHTML += `<img src="${reader.result}" width="256px" height="159px">`;
                    this.setState({ changeImg: "yes" });
                }
                reader.readAsDataURL(file)
            }
        }

        render() {
            let divImg = (
                <img src={img_upload} width="256px" height="159px" />
            );
            if (this.state.Gambar != "") {
                let host = window.location.hostname === "localhost" ? "http://localhost:2005/" : "https://api.srisannano.com/";
                divImg = <img src={host + "file/" + this.state.Gambar} width="256px" height="159px" />
            }
            let act = this.state.id != "" ? "pindapata_edit" : "pindapata_insert"
            return (
                <Fragment>
                    <form id='iniForm' onSubmit={(e) => submitForm(e, { act: act, reload: true })} className="needs-validation" noValidate>
                        <div className='card-form'>
                            <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>{this.state.Title}</h2>
                            <input type="hidden" name="iddata" value={this.state.id} />
                            <input type="hidden" name="token" value={getCookie("Token")} />
                            <input type="hidden" name="changeImg" value={this.state.changeImg} />
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-group mb-3">
                                        <label>Tanggal</label>
                                        <input type="date" name="Tanggal" className="form-control" value={this.state.Tanggal} onChange={(e) => this.handleChange(e, "Tanggal")} required />
                                        <div className="invalid-feedback">Silahkan tentukan tanggal</div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group mb-3">
                                        <label>Jam Broadcast</label>
                                        <input type="time" name="JamBroadcast" className="form-control" value={this.state.JamBroadcast} onChange={(e) => this.handleChange(e, "JamBroadcast")} required />
                                        <div className="invalid-feedback">Silahkan tentukan jam broadcast</div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Pesan</label>
                                <textarea type="text" name="PesanBroadcast" className="form-control" placeholder='masukan Pesan broadcast' value={this.state.PesanBroadcast} onChange={(e) => this.handleChange(e, "PesanBroadcast")} required />
                                <div className="invalid-feedback">Silahkan masukan pesan broadcast</div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Jumlah Bante</label>
                                <input type="number" name="JumlahOrang" className="form-control" placeholder='masukan jumlah bante' value={this.state.JumlahOrang} onChange={(e) => this.handleChange(e, "JumlahOrang")} required />
                                <div className="invalid-feedback">Silahkan masukan jumlah bante</div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 mb-2'>
                                    <div className="form-group mb-3">
                                        <label>Organisasi</label>
                                        <select name="IDCampaign" className="form-select" value={this.state.IDCampaign} onChange={(e) => this.setState({ IDCampaign: e.target.value })} >
                                            {
                                                this.state.Organisasi.map((item, i) => {
                                                    return <option value={item.ID} key={i}>{item.NamaCampaign}</option>
                                                })
                                            }
                                        </select>
                                        <div className="invalid-feedback">Silahkan pilih organisasi</div>
                                    </div>
                                </div>
                                <div className='col-md-6 mb-2'>
                                    <div className="form-group mb-3">
                                        <label>Wilayah</label>
                                        <select name="IDLokasi" className="form-select" value={this.state.IDLokasi} onChange={(e) => this.handleChange(e, "IDLokasi")} >
                                            {
                                                this.state.Lokasi.map((item, i) => {
                                                    return <option value={item.ID} key={i}>{item.NamaLokasi}</option>
                                                })
                                            }
                                        </select>
                                        <div className="invalid-feedback">Silahkan pilih lokasi</div>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group'>
                                <label>Tag Lokasi</label>
                                <div className='input-group'>
                                    <input type="text" name="TagLokasi" className="form-control" value={this.state.TagLokasi} onChange={(e) => this.handleChange(e, "TagLokasi")} />
                                    <button type='button' className='btn btn-secondary material-icons' data-bs-toggle="modal" data-bs-target="#exampleModal">info</button>
                                </div>
                                <div className="invalid-feedback">Silahkan masukan tag lokasi</div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Alamat</label>
                                <textarea type="text" name="Alamat" className="form-control" placeholder='masukan alamat' id="edtAlamat" value={this.state.Alamat} onChange={(e) => this.handleChange(e, "Alamat")} />
                                <div className="invalid-feedback">Silahkan masukan alamat</div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Keterangan</label>
                                <textarea type="text" name="Description" className="form-control" placeholder='masukan Katerangan' value={this.state.Description} onChange={(e) => this.handleChange(e, "Description")} />
                                <div className="invalid-feedback">Silahkan masukan alamat</div>
                            </div>
                            <div className='form-group'>
                                <label>Gambar</label>
                                <div className='image-upload'>
                                    <label id="labelImg" for="image-upload">
                                        {divImg}
                                    </label>
                                    <input type="file" accept='image/*' name="Img" id="image-upload" onChange={(e) => this.openFile(e)} required={this.state.isReqImg} />
                                    <div className="invalid-feedback">Silahkan pilih Gambar</div>
                                </div>
                            </div>
                            <p></p>
                            <button type="submit" className='btn  btn-default'><span></span> Simpan</button>
                        </div>
                    </form>

                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Tutorial Ambil</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h6>1. Silahkan masuk ke  <a href="https://maps.google.com/" target='__blank'>google maps</a></h6>
                                    <h6>2. Selanjutnya silahkan pilih lokasi atau tempat yang ingin anda ambil kordinatnya, lalu click kanan pada lokasi tersebut dan click kordinat yang muncul seperti gambar di bawah</h6>
                                    <img src={img_contoh} width="100%" />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Form />, document.getElementById('tampil'));
}
export default App;
