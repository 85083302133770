import '../lp.css';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { api } from '../modul.js';

const sql = api("landingpage", {});
const host = window.location.hostname === "localhost" ? "http://localhost:2005/api_mobile/" : "https://api.srisannano.com/api_mobile/";
const hostFile = window.location.hostname === "localhost" ? "http://localhost:2005/file/" : "https://api.srisannano.com/file/";
function App() {
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                lokasi: 0,
                kategori: 0,
                acara: 0,
                dom: [],
                resolusi: window.matchMedia("(max-width: 700px)").matches
            };
        }
        async componentDidMount() {
            let sql = await api("data_landingpage", {});
            this.setState({
                lokasi: sql.lokasi,
                kategori: sql.kategori,
                acara: sql.acara
            });
            let dom = [];
            if (this.state.resolusi) {
                dom = (
                    <Fragment>
                        <div className='banner'>
                            <div className='logo'>
                                <img src={hostFile + "logo.png"} alt='logo' style={{ height: "57px" }} />
                            </div>
                            <h3 style={{ fontSize: "24px", textAlign: "center" }}>
                                {sql.data.BannerHeader} <span style={{ color: "#FFE46B" }}>APLIKASI <br></br> SRISANNANO</span>
                            </h3>
                            <div style={{ textAlign: "center" }}>
                                <span style={{ fontSize: "12px" }}>{sql.data.BannerText}</span>
                            </div>
                            <img src={hostFile + 'img-banner.png'} alt='banner' style={{ width: "100%" }} />
                        </div>
                        {/* middle */}
                        <div className='div-middle'>
                            <div className='row' style={{ paddingTop: "90px", margin: "0 16px 0 16px" }}>
                                <div className='col-md-6'>
                                    <img src={hostFile + sql.data.ContentImg} alt='img1' style={{ width: "100%" }} />
                                </div>
                                <div className='col-md-6' style={{ paddingLeft: "16px" }}>
                                    <h3 style={{ fontSize: "20px", marginTop: "20px" }}>{sql.data.ContentHeader}</h3>
                                    <span style={{ fontSize: "16px" }}>{sql.data.ContentText}</span>
                                    <div className='list'>
                                        {sql.kategori.map(kat => {
                                            return (
                                                <div className='d-flex justify-content-start align-items-center gap-3' style={{ paddingBottom: "40px" }}>
                                                    <img src={hostFile + kat.Gambar} alt='icon' style={{ width: "60px", height: "60px" }} />
                                                    <span style={{ fontSize: "16px" }}>{kat.NamaKategori}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end middle */}
                        <div className='div-app'>
                            <div className='row'>
                                <div className='col-md-6' style={{ paddingTop: "60px", textAlign: "center", paddingLeft: "16px", paddingRight: "16px" }}>
                                    <h3 style={{ fontSize: "20px" }}>{sql.data.AppHeader}</h3>
                                    <span style={{ fontSize: "12px" }}>
                                        {sql.data.AppText}
                                    </span>
                                    <div className='row'>
                                        <div className='col-6' onClick={window.open(sql.AppLinkPlayStore)} style={{ paddingTop: "32px", paddingBottom: "79px" }}>
                                            <img src={hostFile + sql.data.AppPlayStoreImg} alt='img1' style={{ width: "179px", height: "52px" }} />
                                        </div>
                                        <div className='col-6' onClick={window.open(sql.AppLinkAppStore)} style={{ paddingTop: "32px", paddingBottom: "79px" }}>
                                            <img src={hostFile + sql.data.AppLinkAppStoreImg} alt='img2' style={{ width: "179px", height: "52px" }} />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <center><img src={hostFile + sql.data.AppImg} alt='img1' style={{ width: "241px" }} /></center>
                                </div>
                            </div>
                        </div>
                        {/* end of end */}
                        <div className='div-middle-end'>
                            <div className='card card-middle' >
                                <div className='card-header'></div>
                                <div className='card-body'>
                                    <h3 style={{ fontSize: "20px", paddingTop: "99px" }}>{sql.data.CardCTHeader}</h3>
                                    <span style={{ fontSize: "14px", paddingBotom: "52px" }}>{sql.data.CardCTText}</span>
                                    <center>
                                        <div className='row' style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "40px" }}>
                                            <div className='col-md-4 mb-2'>
                                                <div className='card card-ct'>
                                                    <h4 style={{ fontSize: "16px" }}>Lokasi Wilayah</h4>
                                                    <h2 style={{ fontSize: "32px" }}>{this.state.lokasi}</h2>
                                                </div>
                                            </div>
                                            <div className='col-md-4 mb-2'>
                                                <div className='card card-ct'>
                                                    <h4 style={{ fontSize: "16px" }}>Kategori Donasi</h4>
                                                    <h2 style={{ fontSize: "32px" }}>{this.state.kategori}</h2>
                                                </div>
                                            </div>
                                            <div className='col-md-4 mb-2'>
                                                <div className='card card-ct2'>
                                                    <h4 style={{ fontSize: "16px" }}>Total event </h4>
                                                    <h2 style={{ fontSize: "32px" }}>{this.state.acara}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </center>
                                </div>
                            </div>
                        </div>
                        {/* end of end end */}
                        <div className='div-end'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <img src={hostFile + sql.data.AboutImg} alt='img1' style={{ width: "343px", height: "190px" }} />
                                </div>
                                <div className='col-md-6'>
                                    <h3 style={{ fontSize: "20px", paddingTop: "20px" }}>{sql.data.AboutHeader}</h3>
                                    <span style={{ fontSize: "12px" }}>
                                        {sql.data.AboutText}
                                    </span>
                                </div>

                            </div>
                        </div>
                        <div className='div-footer'>
                            <center>
                                <img src={hostFile + 'logo.png'} alt='icon' style={{ width: "84px", height: "107px", textAlign: "center" }} />
                                <div className='d-flex justify-content-start align-items-center gap-3' style={{ paddingBottom: "27px" }}>
                                    <img src={hostFile + 'icon-email.png'} alt='icon' style={{ width: "32px", height: "32px" }} />
                                    <a href='mailto:SriSannano@gmail.com'>{sql.data.FooterEmail}</a>
                                </div>
                                <div className='d-flex justify-content-start align-items-center gap-3' style={{ paddingBottom: "27px" }}>
                                    <img src={hostFile + 'icon-telp.png'} alt='icon' style={{ width: "32px", height: "32px" }} />
                                    <a href='#'>{sql.data.FooterTelp}</a>
                                </div>
                            </center>
                        </div>
                    </Fragment>
                );
            } else {
                dom = (
                    <Fragment>
                        <div>
                            <div className='banner'>
                                <div className='logo'>
                                    <img src={hostFile + 'logo.png'} alt='logo' style={{ height: "68px" }} />
                                </div>
                                <h3 style={{ fontSize: "28px", paddingLeft: "70px" }}>
                                    SALURKAN DONASIMU UNTUK MEMBANTU <br></br> MEREKA DENGAN MENGGUNAKAN <span style={{ color: "#FFE46B" }}>APLIKASI <br></br> SRISANNANO</span>
                                </h3>
                                <div style={{ paddingLeft: "70px" }}>
                                    <span style={{ fontSize: "14px" }}>Memberikan kesempatan bagi penggalang dana untuk <br></br> memudahkan dan memperluas bantuan. banyak masyarakat <br></br> masih tidak tahu bagaimana cara kita dapat membantu bila <br></br> terjadi bencana maupun yang membutuhkan</span>
                                </div>
                            </div>
                            <div className='div-middle'>
                                <div className='row' style={{ paddingTop: "220px", paddingLeft: "174px" }}>
                                    <div className='col-md-6'>
                                        <img src={hostFile + 'image_body.png'} alt='img1' style={{ width: "100%" }} />
                                    </div>
                                    <div className='col-md-6' style={{ paddingLeft: "100px" }}>
                                        <h3 style={{ fontSize: "20px", marginTop: "20px" }}>Mereka membutuhkan bantuan kita</h3>
                                        <span style={{ fontSize: "16px" }}>Membantu Yayasan untuk untuk penggalangan dana Seperti : </span>
                                        <div className='list'>
                                            <div className='d-flex justify-content-start align-items-center gap-3' style={{ paddingBottom: "40px" }}>
                                                <img src={hostFile + 'icon.png'} alt='icon' style={{ width: "60px", height: "60px" }} />
                                                <span style={{ fontSize: "16px" }}>Pendidikan</span>
                                            </div>
                                            <div className='d-flex justify-content-start align-items-center gap-3' style={{ paddingBottom: "40px" }}>
                                                <img src={hostFile + 'icon.png'} alt='icon' style={{ width: "60px", height: "60px" }} />
                                                <span style={{ fontSize: "16px" }}>Panti Asuhan</span>
                                            </div>
                                            <div className='d-flex justify-content-start align-items-center gap-3' style={{ paddingBottom: "40px" }}>
                                                <img src={hostFile + 'icon.png'} alt='icon' style={{ width: "60px", height: "60px" }} />
                                                <span style={{ fontSize: "16px" }}>Panti Jompo</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='div-app'>
                                <div className='row'>
                                    <div className='col-md-6' style={{ paddingTop: "120px", paddingLeft: "215px" }}>
                                        <h3 style={{ fontSize: "20px" }}>Aplikasi SriSannano membantu kita donasi di seluruh wilayah Indonesia </h3>
                                        <span style={{ fontSize: "12px" }}>
                                            Mau donasi dan membantu orang lain dengan mudah. Yuk download aplikasi Srisannano di play Store dan App Store layanan mudah, cepat dan selalu update perkembangan hasil donasi kamu kepada Yayasan dan Organisaso yang menerimanya.
                                        </span>
                                        <div className='row'>
                                            <div className='col-md-6' style={{ paddingTop: "32px", paddingBottom: "120px" }}>
                                                <img src={hostFile + sql.data.AppPlayStoreImg} alt='img1' style={{ width: "179px", height: "52px" }} />
                                            </div>
                                            <div className='col-md-6' style={{ paddingTop: "32px", paddingBottom: "120px" }}>
                                                <img src={hostFile + sql.data.AppAppStoreImg} alt='img2' style={{ width: "179px", height: "52px" }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <img src={hostFile + sql.data.AppImg} alt='img1' style={{ width: "514px", position: "relative", top: "-150px" }} />
                                    </div>
                                </div>
                            </div>
                            <div className='div-middle-end'>
                                <div className='card card-middle' >
                                    <div className='card-header'></div>
                                    <div className='card-body'>
                                        <h3 style={{ fontSize: "20px", paddingTop: "99px" }}>Aplikasi Srisannano</h3>
                                        <span style={{ fontSize: "14px", paddingBotom: "52px" }}>Berikut daftar Lokasi Wilayah, Kategori Donasi dan Total Event Keseluruhan yang terdapat di aplikasi Srisannano</span>
                                        <div className='row' style={{ paddingLeft: "250px", paddingRight: "250px", paddingTop: "52px" }}>
                                            <div className='col-md-4 mb-2'>
                                                <div className='card card-ct'>
                                                    <h4 style={{ fontSize: "16px" }}>Lokasi Wilayah</h4>
                                                    <h2 style={{ fontSize: "32px" }}>{this.state.lokasi}</h2>
                                                </div>
                                            </div>
                                            <div className='col-md-4 mb-2'>
                                                <div className='card card-ct'>
                                                    <h4 style={{ fontSize: "16px" }}>Kategori Donasi</h4>
                                                    <h2 style={{ fontSize: "32px" }}>{this.state.kategori}</h2>
                                                </div>
                                            </div>
                                            <div className='col-md-4 mb-2'>
                                                <div className='card card-ct2'>
                                                    <h4 style={{ fontSize: "16px" }}>Total event</h4>
                                                    <h2 style={{ fontSize: "32px" }}>{this.state.acara}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='div-end'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <h3 style={{ fontSize: "24px" }}>Tentang SriSannano</h3>
                                        <span style={{ fontSize: "14px" }}>
                                            Srisannano sebuah platform yang membantu yayasan untuk mendapatkan penggalangan dana dari masyarakan Indonesia. Akan banyak yayasan yang akan tergabung dari berbagai daerah di seluruh Indonesia untuk
                                        </span>
                                    </div>
                                    <div className='col-md-6'>
                                        <img src={hostFile + './image-btn.png'} alt='img1' style={{ width: "500px", height: "334px" }} />
                                    </div>
                                </div>
                            </div>
                            <div className='div-footer'>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <img src={hostFile + './logo.png'} alt='icon' style={{ width: "44px", height: "56px" }} />
                                    <div className='d-flex justify-content-start align-items-center gap-3' style={{ paddingLeft: "153px" }}>
                                        <img src={hostFile + 'icon-email.png'} alt='icon' style={{ width: "32px", height: "32px" }} />
                                        <a href='mailto:SriSannano@gmail.com'>SriSannano@gmail.com</a>
                                    </div>
                                    <div className='d-flex justify-content-start align-items-center gap-3' style={{ paddingLeft: "39px" }}>
                                        <img src={hostFile + 'icon-telp.png'} alt='icon' style={{ width: "32px", height: "32px" }} />
                                        <a href='#'>02139708800</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }
            this.setState({ dom: dom });
        }

        render() {
            return (this.state.dom)
        }
    }
    ReactDOM.render(<Main />, document.getElementById('root'));
}

export default App;
