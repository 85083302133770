import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { saiki, api, loading, rendModal, rendTable, getCookie, submitForm } from "../modul";
import img_upload from '../bg_uppload-image.png';

function App() {
    document.title = "Notifikasi";
    var typingTimer;
    var delay = 500;

    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "ID",
                by: "DESC",
                idlocation: 0,
                data: {},
                detail: []
            };
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            clearTimeout(typingTimer);
            typingTimer = setTimeout(async () => {
                this.main();
            }, delay);
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => { this.main(); }, 100);
        }

        async modalInfo(e) {
            let sql = await api("notifikasi_detail", { token: getCookie("Token"), iddata: e.target.dataset.id });
            console.log(sql);
            if (sql.status == "true") {
                this.setState({
                    data: sql.data,
                    detail: sql.detail
                })
                var myModal = new window.bootstrap.Modal(document.getElementById('modalUmat'), {});
                myModal.show();
            }

        }

        async main() {
            let param = {
                search: this.state.search,
                index: this.state.index,
                searchcount: this.state.searchcount,
                token: getCookie("Token"),
                sort: this.state.sort,
                by: this.state.by
            }
            let sql = await api("notifikasi_browse", param);
            let field = {
                thead: [
                    { cap: "", sort: "", type: "opsi", width: "100px" },
                    { cap: "Tanggal", sort: "TanggalBuat", width: "150px" },
                    { cap: "Umat", sort: "Umat", width: "150px", type:'str'},
                    // { cap: "Tipe", sort: "TypePesan", width: "150px" },
                    { cap: "Judul", sort: "Judul" },
                    { cap: "Deskripsi", sort: "Pesan" },
                ],
                j: sql.ct
            }
            rendTable({
                dataset: { data: sql.data, field: field },
                div: "divView",
                table: { cls: "table  " },
                thead: { cls: "thead-dark" },
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                tbody: {
                    id: "tbodyDataBanner",
                    opsi: [
                        { type: "view", event: (e) => this.modalInfo(e) },
                    ]
                },
                paggination: true
            });
        }

        componentDidMount() {
            ReactDOM.render("", document.getElementById('divHeaderLabel'));
            this.main();
            loading();
        }

        render() {
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Notifikasi</h2>
                        <div className='row'>
                            <div className='col-md-9'>
                                <button className="btn btn-default" onClick={(e) => rendForm(e)}>+ Tambah</button>
                            </div>
                            <div className='col-md-3'>
                                <div className='input-icons'>
                                    <span className='material-icons icon-search'>search</span>
                                    <input className="form-control search-form" type="search" name="search" placeholder="Search" value={this.state.search} onChange={(e) => this.handleChange(e, "search")} required />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView"></div>
                    </div>
                    <nav id="paggination" style={{ paddingRight: "50px" }}></nav>

                    <div className="modal fade" id="modalUmat" tabindex="-1" aria-labelledby="modalUmat" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Detail Notifikasi</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <table className='table'>
                                        <tr>
                                            <td>Judul</td>
                                            <td>:</td>
                                            <td>{this.state.data.Judul}</td>
                                        </tr>
                                        <tr>
                                            <td>Tanggal</td>
                                            <td>:</td>
                                            <td>{this.state.data.TanggalBuat}</td>
                                        </tr>
                                        <tr>
                                            <td>Judul</td>
                                            <td>:</td>
                                            <td>{this.state.data.Pesan}</td>
                                        </tr>
                                    </table>
                                    <h5>Kirim Ke {this.state.detail.length} Umat</h5>
                                    <table className='table table-stripped'>
                                        <thead>
                                            <tr>
                                                <th>Nama</th>
                                                <th>Terkirim</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.detail.map((itel, i) => {
                                                    return (
                                                        <tr>
                                                            <td>{itel.Nama}</td>
                                                            <td>{itel.WaktuKirim}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" id='btnBatalPwd' className="btn btn-secondary" data-bs-dismiss="modal">Batal</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                TypePesan: "all",
                Judul: "",
                Pesan: "",
                Waktu: "",
                UserID: "",
                UserName: "",
                TanggalBuat: saiki(),
                changeImg: "yes",
                Title: "Tambah Notifikasi",
                dataUmat: [],
                arrUmat: []
            }
        }

        async componentDidMount() {
            ReactDOM.render(<div className='btn-kembali' onClick={() => App()}><spam className="material-icons">arrow_back</spam> Kembali</div>, document.getElementById('divHeaderLabel'));
            let sql = await api("notifikasi_detail", { iddata: this.state.id, token: getCookie("Token") });
            if (this.state.id !== "") {
                this.setState({
                    isReqImg: false,
                    TypePesan: sql.data.TypePesan,
                    Judul: sql.data.Judul,
                    Pesan: sql.data.Pesan,
                    Waktu: sql.data.Waktu,
                    UserID: sql.data.UserID,
                    UserName: sql.data.UserName,
                    TanggalBuat: sql.data.TanggalBuat,
                    changeImg: "no",
                    Title: "Edit Notifikasi"
                });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        async handleCariUmat(val) {
            let sql = await api("donatur_browse", { search: val, token: getCookie("Token") });
            console.log(sql);
            if (sql.status == "true") this.setState({ dataUmat: sql.data });
        }

        openFile(e) {
            document.getElementById("labelImg").innerHTML = '';
            let files = e.target.files;
            let file;
            for (let i = 0; i < files.length; i++) {
                let reader = new FileReader();
                file = files[i];
                reader.onload = (file) => {
                    document.getElementById("labelImg").innerHTML += `<img src="${reader.result}" width="256px" height="159px">`;
                    this.setState({ changeImg: "yes" });
                }
                reader.readAsDataURL(file)
            }
        }

        handlePilih(item) {
            let data = this.state.arrUmat;
            data.push({ UserID: item.ID, Nama: item.Nama, Telepon: item.Telepon, Email: item.Email, TokenFirebase: item.TokenFirebase })
            this.setState({ arrUmat: data });
        }

        render() {
            let act = this.state.id ? "notifikasi_edit" : "notifikasi_insert";
            return (
                <Fragment>
                    <form id='iniForm' onSubmit={(e) => submitForm(e, { act: act, reload: true })} className="needs-validation" noValidate>
                        <div className='card-form'>
                            <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>{this.state.Title}</h2>
                            <input type="hidden" name="iddata" value={this.state.id} />
                            <input type="hidden" name="token" value={getCookie("Token")} />
                            <div className="form-group mb-3">
                                <label>Judul</label>
                                <input type="text" name="Judul" className="form-control" placeholder='masukan nama Banner' value={this.state.Judul} onChange={(e) => this.handleChange(e, "Judul")} required />
                                <div className="invalid-feedback">Silahkan masukan Judul</div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Deskripsi</label>
                                <textarea name="Pesan" className="form-control" placeholder='tuliskan deskripsi' value={this.state.Pesan} onChange={(e) => this.handleChange(e, "Pesan")} required />
                                <div className="invalid-feedback">Silahkan tulikan deskripsi</div>
                            </div>
                            {/* <div className="form-group mb-3">
                                <label>Tanggal</label>
                                <input type="date" name="TanggalBuat" className="form-control" placeholder='pilih tanggal buat' value={this.state.TanggalBuat} onChange={(e) => this.handleChange(e, "TanggalBuat")} required />
                                <div className="invalid-feedback">Silahkan pilih tanggal buat</div>
                            </div> */}
                            <div className="form-group mb-3">
                                <label>Jenis Target</label>
                                <select className='form-select' name="TypePesan" value={this.state.TypePesan} onChange={(e) => this.setState({ TypePesan: e.target.value })} required>
                                    <option value="all">Semua User</option>
                                    <option value="user">User Tertentu</option>
                                </select>
                                <div className="invalid-feedback">Silahkan pilih type pesan</div>
                            </div>
                            <textarea value={JSON.stringify(this.state.arrUmat)} name="UserID" style={{ display: "none" }} />
                            <div style={{ display: this.state.TypePesan == "all" ? "none" : "block" }}>
                                <buttom type="button" className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#modalUmat" onClick={() => this.handleCariUmat("")}>Tambah Umah</buttom>
                                <p></p>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Nama</th>
                                            <th>Telp</th>
                                            <th>Email</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.arrUmat.map((item, i) => {
                                                if (item.TokenFirebase != "") {
                                                    return (<tr>
                                                        <td>{item.Nama}</td>
                                                        <td>{item.Telepon}</td>
                                                        <td>{item.Email}</td>
                                                    </tr>)
                                                }
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <p></p>
                            <button type="submit" className='btn btn-default'><span></span> Simpan</button>
                        </div>
                    </form>

                    <div className="modal fade" id="modalUmat" tabindex="-1" aria-labelledby="modalUmat" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content shadow">
                                <div className="modal-header">
                                    <h5 className="modal-title">Data Umat</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <input className='form-control' type="search" placeholder='Cari Nama Umat' onChange={(e) => this.handleCariUmat(e.target.value)} />
                                    <p></p>
                                    <div className='table-responsive'>
                                        <table className='table table-stripped' >
                                            <thead>
                                                <tr>
                                                    <th>Nama</th>
                                                    <th>Telp</th>
                                                    <th>Email</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.dataUmat.map((item, i) => {
                                                        if (item.TokenFirebase != "") {
                                                            return (
                                                                <tr key={i} onClick={() => this.handlePilih(item)} data-bs-dismiss="modal">
                                                                    <td>{item.Nama}</td>
                                                                    <td>{item.Telepon}</td>
                                                                    <td>{item.Email}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" id='btnBatalPwd' className="btn btn-secondary" data-bs-dismiss="modal">Batal</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Form />, document.getElementById('tampil'));
}
export default App;