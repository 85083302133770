import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { saiki, api, loading, rendModal, rendTable, getCookie, submitForm, numberFormat } from "../modul";

function App() {
    document.title = "Partisipan";
    var typingTimer;
    var delay = 500;

    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "Nama",
                by: "ASC",
                idlocation: 0,
                JumlahUmat: 0
            };
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            clearTimeout(typingTimer);
            typingTimer = setTimeout(async () => {
                this.main();
            }, delay);
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => { this.main(); }, 100);
        }

        modalDelete(ini) {
            let dom = (
                <Fragment>
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </Fragment>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "donatur_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        async main() {
            let param = {
                search: this.state.search,
                index: this.state.index,
                searchcount: this.state.searchcount,
                token: getCookie("Token"),
                sort: this.state.sort,
                by: this.state.by
            }
            let sql = await api("donatur_browse", param);
            console.log(sql);
            this.setState({ JumlahUmat: sql.JumlahUmat })
            let field = {
                thead: [
                    { cap: "", sort: "", type: "opsi", width: "50px" },
                    { cap: "Foto", sort: "Gambar", type: "img", width: "120px", align: "center" },
                    { cap: "Nama", sort: "Nama", width: "150px" },
                    { cap: "Alamat Email", sort: "Email", width: "200px" },
                    { cap: "Tanggal Lahir", sort: "TanggalLahir", width: "100px" },
                    { cap: "Nomor Telp", sort: "Telepon", type: "str" }
                ],
                j: sql.ct
            }
            rendTable({
                dataset: { data: sql.data, field: field },
                div: "divView",
                table: { cls: "table  " },
                thead: { cls: "thead-dark" },
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                tbody: {
                    id: "tbodyDataVideo",
                    opsi: [
                        { type: "other", icon: "visibility", event: (e) => rendForm(e) }
                    ]
                },
                paggination: true
            });
        }

        componentDidMount() {
            ReactDOM.render("", document.getElementById('divHeaderLabel'));
            this.main();
            loading();
        }

        render() {
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Data Umat</h2>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card card-filter shadow-tipis" style={{ borderRadius: "12px", cursor: "pointer", border: "none" }}>
                                    <div className="card-body" style={{ minHeight: "80px", textAlign: "left", borderTop: "solid 10px #57EAE1", borderRadius: "12px" }}>
                                        <span className='material-icons' style={{ color: "#57EAE1", backgroundColor: "#C4F8F4", padding: "8px", borderRadius: "8px" }}>self_improvement</span>
                                        <br></br>
                                        <span style={{ fontSize: "16px" }}>Jumlah Umat</span>
                                        <h1 style={{ fontSize: "32px", color: "#404040" }}>{this.state.JumlahUmat} Umat</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-9'>
                            </div>
                            <div className='col-md-3'>
                                <div className='input-icons'>
                                    <span className='material-icons icon-search'>search</span>
                                    <input className="form-control search-form" type="search" name="search" placeholder="Search" value={this.state.search} onChange={(e) => this.handleChange(e, "search")} required />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView"></div>
                    </div>
                    <nav id="paggination" style={{ paddingRight: "50px" }}></nav>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                totalDonasi: 0,
                totalEvent: 0,
                Nama: ""
            }
        }

        async componentDidMount() {
            ReactDOM.render(<div className='btn-kembali' onClick={() => App()}><spam className="material-icons">arrow_back</spam> Kembali</div>, document.getElementById('divHeaderLabel'));
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => { this.main(); }, 100);
        }

        async main() {
            let sql = await api("donatur_detail", { iddata: this.state.id, token: getCookie("Token") });
            console.log(sql.sum);
            this.setState({
                Nama: sql.sum.Nama,
                totalDonasi: sql.sum.Total,
                totalEvent: sql.sum.CT
            });

            let field = {
                thead: [
                    { cap: "Penggalang Dana", sort: "NamaCampaign", width: "250px" },
                    { cap: "Judul Event", sort: "NamaEvent", width: "250px" },
                    { cap: "Nominal", sort: "Nominal", width: "100px" },
                    { cap: "Tanggal", sort: "Tanggal" },
                ],
                j: sql.ct
            }
            rendTable({
                dataset: { data: sql.data, field: field },
                div: "divView",
                table: { cls: "table  " },
                thead: { cls: "thead-dark" },
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                tbody: {
                    id: "tbodyDataVideo",
                },
                paggination: true
            });
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        render() {
            return (
                <Fragment>
                    <div className='card-form'>
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Detail / {this.state.Nama}</h2>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="card card-donasi-biru">
                                    <div className="card-body" style={{ minHeight: "80px", textAlign: "left" }}>
                                        <div className='d-flex justify-content-start align-items-center gap-2'>
                                            <span className='material-icons' style={{ color: "#57EAE1", backgroundColor: "#DCFFFD", padding: "8px", borderRadius: "8px" }}>calendar_month</span>
                                            <span style={{ fontSize: "16px" }}>Total Event di donasi</span>
                                        </div>
                                        <h1 style={{ fontSize: "32px" }}>{this.state.totalEvent} Event</h1>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="card card-donasi-biru">
                                    <div className="card-body" style={{ minHeight: "80px", textAlign: "left" }}>
                                        <div className='d-flex justify-content-start align-items-center gap-2'>
                                            <span className='material-icons' style={{ color: "#57EAE1", backgroundColor: "#DCFFFD", padding: "8px", borderRadius: "8px" }}>calendar_month</span>
                                            <span style={{ fontSize: "16px" }}>Total donasi</span>
                                        </div>
                                        <h1 style={{ fontSize: "32px" }}>Rp. {numberFormat(this.state.totalDonasi)}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className='table-responsive' id='divView'></div>
                    </div>
                    <nav id="paggination" style={{ paddingRight: "50px" }}></nav>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Form />, document.getElementById('tampil'));
}
export default App;