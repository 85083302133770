import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { saiki, api, getCookie, TampilBulan, tanggalIndo } from "../modul";
import { Line } from 'react-chartjs-2';
import cardeven from '../cardeven.png';
import carddana from '../carddana.png';
import carddonasi from '../carddonasi.png';

function App() {
    document.title = "Dashboard";
    var typingTimer;
    var delay = 500;

    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                Bln: saiki().substr(0, 7),
                Thn: saiki().substr(0, 4),
                totalEvent: 0,
                totalDana: 0,
                totalDonasi: 0,
                arrData: [],
                arrLabel: [],
                mode: "bulan",
                blnType: "block",
                thnType: "none",
                lblData: "",
                arrThn: [2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023]
            };
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            if (ssts == "Bln") {
                this.handleFilter("bulan");
            } else {
                this.handleFilter("tahun");
            }
        }

        handleFilter(fil) {
            if (fil == "bulan") {
                this.setState({ blnType: "block", thnType: "none", mode: "bulan", lblData: TampilBulan(this.state.Bln) });
            } else {
                this.setState({ blnType: "none", thnType: "block", mode: "tahun", lblData: "Tahun " + this.state.Thn });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        async main() {
            let sql = await api("dashboard", { token: getCookie("Token"), mode: this.state.mode, bln: this.state.Bln, thn: this.state.Thn });
            let tmpData = [], tmpLabel = [];
            for (let dd of sql.data) {
                tmpData.push(dd.JML);
                tmpLabel.push(dd.Tanggal);
            }
            this.setState({ totalEvent: sql.even, totalDana: sql.campaign, totalDonasi: sql.donasi, arrLabel: tmpLabel, arrData: tmpData });
        }

        // get full day of month in array
        getDaysInMonth() {
            let bln = this.state.Bln.substr(5, 2);
            let thn = this.state.Bln.substr(0, 4);
            return new Date(thn, bln, 0).getDate();
        }

        async componentDidMount() {
            this.main();
        }

        render() {
            let state = {
                labels: this.state.arrLabel,
                datasets: [{
                    label: this.state.lblData,
                    fill: true,
                    lineTension: 0.1,
                    borderColor: '#FF7800',
                    data: this.state.arrData
                }]
            }
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Dashboard</h2>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card shadow-tipis" style={{ borderRadius: "12px", border: "none" }}>
                                    <div className="card-body" style={{ height: "160px", textAlign: "left", padding: "20px", borderTop: "solid 10px #57EAE1", borderRadius: "12px" }}>
                                        <h1 style={{ textAlign: "left", fontSize: "32px" }}>{this.state.totalEvent}</h1>
                                        <span style={{ fontSize: "16px", color: "#7C7C7C" }}>Event</span>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <span style={{ color: "#DBBB2A", fontSIze: "14px" }}><i className='material-icons'>calendar_month</i> {tanggalIndo(saiki())}</span>
                                            <img src={cardeven} alt='Image Event' style={{ width: "40px" }}></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="card shadow-tipis" style={{ borderRadius: "12px", border: "none" }}>
                                    <div className="card-body" style={{ height: "160px", textAlign: "left", borderTop: "solid 10px #FFE46B", borderRadius: "12px" }}>
                                        <h1 style={{ textAlign: "left", fontSize: "32px" }}>{this.state.totalDana}</h1>
                                        <span style={{ fontSize: "16px", color: "#7C7C7C" }}>Penggalangan Dana</span>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <span style={{ color: "#DBBB2A", fontSIze: "14px" }}><i className='material-icons'>calendar_month</i> {tanggalIndo(saiki())}</span>
                                            <img src={carddana} alt='Image Event' style={{ width: "40px" }}></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="card shadow-tipis" style={{ borderRadius: "12px", border: "none" }}>
                                    <div className="card-body" style={{ height: "160px", textAlign: "left", borderTop: "solid 10px #FF7800", borderRadius: "12px" }}>
                                        <h1 style={{ textAlign: "left", fontSize: "32px" }}>{this.state.totalDonasi}</h1>
                                        <span style={{ fontSize: "16px", color: "#7C7C7C" }}>Donation</span>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <span style={{ color: "#DBBB2A", fontSIze: "14px" }}><i className='material-icons'>calendar_month</i> {tanggalIndo(saiki())}</span>
                                            <img src={carddonasi} alt='Image Event' style={{ width: "40px" }}></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="row">
                            <div className="col-md-9">
                                <div className="form-group">
                                    <label style={{ fontSize: "16px" }}>Periode</label>
                                    <input type="month" name="edtD1" id="edtD1" style={{ maxWidth: "200px", display: this.state.blnType }} className="form-control form-filter" value={this.state.Bln} onChange={(e) => this.handleChange(e, "Bln")} />
                                    <select id="edtMonth" style={{ maxWidth: "200px", display: this.state.thnType }} className="form-select form-filter" value={this.state.Thn} onChange={(e) => this.handleChange(e, "Thn")} >
                                        {
                                            this.state.arrThn.map((item, index) => {
                                                return (
                                                    <option key={index} value={item}>{item}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-3 d-flex justify-content-end align-items-center">
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link nav-link-orange active" style={{ borderRadius: "6px 0px 0px 6px" }} id="pills-hari-tab" data-bs-toggle="pill" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={(e) => this.handleFilter('bulan')}>BULAN</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link nav-link-orange" style={{ borderRadius: "0px 6px 6px 0px" }} id="pills-bulan-tab" data-bs-toggle="pill" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={(e) => this.handleFilter('tahun')}>TAHUN</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="table-responsive" id="divView" style={{ maxHeight: "456px" }}>
                            <Line data={state} style={{ maxHeight: "450px" }} />
                        </div>
                        <nav id="paggination" style={{ paddingRight: "50px" }}></nav>
                    </div>
                </Fragment >
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

export default App;