import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import profile from './profile.jpg';
import { rendMenu, getCookie, logout, api, pesan } from './modul.js';
import Dashboard from './page/Dashboard';
import Penggalangandana from './page/Penggalangandana';
import Event from './page/Event';
import Donasi from './page/Donasi';
import Notifikasi from './page/Notifikasi';
import Partisipan from './page/Partisipan';
import Admin from './page/Admin';
import Login from './page/Login';
import Lokasi from './page/Lokasi';
import Kontenlandingpage from './page/Kontenlandingpage';
import Masterkategori from './page/Masterkategori';
import Banner from './page/Banner';
import Video from './page/Video';
import Images from './page/Images';
import Toptrending from './page/Toptrending';
import Breakingnews from './page/Breakingnews';
import Religion from './page/Religion';
import Quotes from './page/Quotes';
import Article from './page/Article';
import Landingpage from './page/Landingpage';
import Explore from './page/Explore';
import Kategoriexplore from './page/Kategoriexplore';
import Map from './page/Map';
import Kantin from './page/Kantin';
import Pindapata from './page/Pindapata';

function App() {
  setTimeout(async () => {
    let __Token = getCookie("Token") || "none";
    if (__Token != "none") {
      let cekLogin = await api("admin_relogin", { token: __Token });
      if (cekLogin.status == "true") {
        rendMenu();
        window.addEventListener('popstate', function (event) {
          if (event.state != null) {
            let dataMenu = window.location.pathname.replace("/", "");
            pilihMenu(dataMenu, false);
          }
        });
      } else {
        logout();
      }
    } else {
      ReactDOM.render(<Login />, document.getElementById('root'));
    }
  }, 500);

  return (<Fragment>
    <div style={{ position: 'fixed', bottom: '0px', height: '100%', width: '100%' }}>
      <div className="wrapper">
        <nav id="sidebar">
          <div className="sidebar-header" onClick={(e) => window.location.reload()}>
            <center><img src="logo.png" alt="Broadway" className='logo-panjang' width="100%" /></center>
          </div>
          <div className="transbox">
            <ul className="list-unstyled components" id="divMenu"></ul>
          </div>
        </nav>
        <div id="content">
          <div className="card" style={{ padding: '5px 10px 0 0', backgroundColor: '#FEFEFE', borderRadius: '60px 0 0 0', border: "none" }}>
            <div className='row' style={{ marginLeft: "39px", marginRight: "52px", border: "none" }}>
              <div className='col-md-9' style={{ marginTop: "41px" }}>
                <div className='d-flex justify-content-start align-items-center' id='divHeaderLabel'></div>
              </div>
              <div className='col-md-3' style={{ marginTop: "20px" }}>
                <div className='d-flex justify-content-end align-items-center'>
                  <label style={{ fontSize: '16px', paddingRight: "24px" }} id="lblNamaAdmin">Hai, {getCookie("NamaAdmin")}</label> <img src={profile} className='img-profile' />
                </div>
              </div>
            </div>
          </div>
          <div id="tampil" className="card" style={{ border: "none", minHeight: '95vh', maxHeight: '95vh', borderRadius: '0 0 0 60px', overflowY: 'scroll', paddingBottom: "50px" }}></div>
        </div>
      </div>
    </div>

    <div className="modal fade" id="modalReset" tabindex="-1" aria-labelledby="modalReset" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <form className='needs-validation' onSubmit={(e) => submitResetPwd(e)} noValidate>
            <input name='token' value={getCookie("Token")} type="hidden" />
            <div className="modal-header">
              <h5 className="modal-title">Ganti Kata Sandi</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className='form-group'>
                <label>Password Lama</label>
                <input type="password" name="PasswordLama" className='form-control' placeholder='Masukan Password Lama' required />
                <div className='invalid-feedback'>Silahkan Masukan Password lama</div>
              </div>
              <div className='form-group'>
                <label>Password Baru</label>
                <input type="password" name="PasswordBaru" className='form-control' placeholder='Masukan Password Baru' required />
                <div className='invalid-feedback'>Silahkan Masukan Password Baru</div>
              </div>
              <div className='form-group'>
                <label>Ulangi Password Baru</label>
                <input type="password" name="PasswordReBaru" className='form-control' placeholder='Ulangi Password Baru' required />
                <div className='invalid-feedback'>Silahkan Ulangi Password Baru</div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" id='btnBatalPwd' className="btn btn-secondary" data-bs-dismiss="modal">Batal</button>
              <button type="submit" id='btnChangePwd' className="btn btn-primary">Ganti Password</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Fragment >);
}

function submitResetPwd(e) {
  e.preventDefault();
  e.stopPropagation();
  let btn = document.getElementById('btnChangePwd');
  if (e.target.checkValidity()) {
    btn.innerHTML = `<span class="spinner-border spinner-border-sm"></span> Memproses`;
    btn.disabled = true;
    let data = new FormData(e.target);
    let host = window.location.hostname === "localhost" ? "http://localhost:2005/api_mobile/" : "https://api.srisannano.com/api_mobile/";
    fetch(host + "ganti_pwd", {
      method: 'POST',
      body: data,
    }).then(response => response.json()).then(hasil => {
      if (hasil.status === "true") {
        pesan("Proses Success", hasil.message, "primary");
        document.getElementById('btnBatalPwd').click();
        btn.innerHTML = "Ganti Password";
        btn.disabled = false;
      } else {
        alert(hasil.message);
        btn.innerHTML = "Ganti Password";
        btn.disabled = false;
      }
    }).catch((error) => {
      console.log("Error: " + error);
      btn.innerHTML = "Ganti Password";
      btn.disabled = false;
    });
  } else {
    let forms = document.getElementsByClassName('needs-validation');
    let ululu = Array.prototype.filter.call(forms, function (form) {
      form.classList.add('was-validated');
    });
  }
}

export const pilihMenu = function (path, ch = true, Type = false, cap = "") {
  var e = document.getElementsByClassName("active");
  for (let h = 0; h < e.length; h++) { e[h].classList.remove("active"); }
  if (ch == true) window.history.pushState(path, "data", path);
  if (path != 'Explore') {
    if (document.getElementById(path)) document.getElementById(path).className = "active";
  } else {
    if (document.getElementById(Type)) document.getElementById(Type).className = "active";
  }
  switch (path) {
    case 'Dashboard':
      Dashboard();
      break;

    case 'Penggalangandana':
      Penggalangandana();
      break;

    case 'Event':
      Event();
      break;

    case 'Donasi':
      Donasi();
      break;

    case 'Notifikasi':
      Notifikasi();
      break;

    case 'Admin':
      Admin();
      break;

    case 'Partisipan':
      Partisipan();
      break;

    case 'Lokasi':
      Lokasi();
      break;

    case 'Map':
      Map();
      break;

    case 'Kontenlandingpage':
      Kontenlandingpage();
      break;

    case 'Masterkategori':
      Masterkategori();
      break;

    case 'Banner':
      Banner();
      break;

    case 'Video':
      Video();
      break;

    case 'Images':
      Images();
      break;

    case 'Toptrending':
      Toptrending();
      break;

    case 'Kantin':
      Kantin();
      break;

    case 'Pindapata':
      Pindapata();
      break;

    case 'Breakingnews':
      Breakingnews();
      break;

    case 'Religion':
      Religion();
      break;

    case 'Quotes':
      Quotes();
      break;

    case 'Article':
      Article();
      break;

    case 'Kategoriexplore':
      Kategoriexplore();
      break;

    case 'Explore':
      Explore('gambar', Type, cap);
      break;

    case 'Logout':
      logout();
      break;

    case 'Langingpage':
      ReactDOM.render(<Landingpage />, document.getElementById('tampil'));
      break;

    default: {
      let elm = <h1>Tidak ada menu</h1>
      document.title = "Srisannano";
      ReactDOM.render(elm, document.getElementById('tampil'))
    } break;
  }
  var x = window.matchMedia("(max-width: 768px)");
  if (x.matches == true) {
    document.getElementById("sidebar").classList.add("active");
  } else {
    document.getElementById("sidebar").classList.remove("active");
  }
}

export default App;
