import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { rendTable, rendModal, api, saiki, submitForm, loading, getCookie } from '../modul';

function App() {
    var typingTimer;
    var delay = 500;

    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "Nama",
                by: "ASC"
            };
        }

        componentDidMount() {
            ReactDOM.render("", document.getElementById('divHeaderLabel'));
            this.main();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            clearTimeout(typingTimer); // Hapus timer sebelumnya
            typingTimer = setTimeout(async () => {
                this.main();
            }, delay);
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        async modalDelete(e) {
            const dom = (
                <Fragment>
                    <input type="hidden" name="iddata" value={e.target.dataset.id} />
                    <input type="hidden" name="token" value={getCookie("Token")} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </Fragment>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('deleteForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "admin_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#deleteForm" });
            });
        }

        async main() {
            let sql = await api("admin_browse", {
                search: this.state.search,
                searchcount: this.state.searchcount,
                index: this.state.index,
                sort: this.state.sort,
                by: this.state.by,
                idlocation: this.state.idlocation,
                token: getCookie("Token")
            });

            let field = {
                thead: [
                    { cap: "", sort: "", type: "opsi", width: "100px" },
                    { cap: "Nama", sort: "Nama", type: "str", width: "150px" },
                    { cap: "Email", sort: "Email", type: "str", width: "150px" },
                    { cap: "Alamat", sort: "Alamat", type: "str", width: "200px" },
                    { cap: "Telp", sort: "Telp", type: "str" },
                ], j: sql.ct
            }

            let dataset = { data: sql.data, field: field };

            rendTable({
                dataType: "dataset",
                dataset: dataset,
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                table: { cls: "table " },
                thead: { cls: "color-bg-biru" },
                tbody: {
                    opsi: [
                        { type: "edit", event: (e) => rendForm(e) },
                        { type: "delete", event: (e) => this.modalDelete(e) }
                    ],
                    id: "tbodyData"
                }
            });
        }

        render() {
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Master Admin</h2>
                        <div className='row'>
                            <div className='col-md-9'>
                                <button className="btn btn-default" onClick={(e) => rendForm(e)}>+ Tambah</button>
                            </div>
                            <div className='col-md-3'>
                                <div className='input-icons'>
                                    <span className='material-icons icon-search'>search</span>
                                    <input className="form-control search-form" type="search" name="search" placeholder="Search" value={this.state.search} onChange={(e) => this.handleChange(e, "search")} required />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView"></div>
                    </div>
                    <nav id="paggination" style={{ paddingRight: "50px" }}></nav>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}


function rendForm(ini) {
    class Form extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                __ID: getCookie("ID"),
                KodeAdmin: "",
                Nama: "",
                Telp: "",
                Alamat: "",
                Gambar: "",
                Email: "",
                Password: "",
                rePassword: "",
                changeImg: 'no',
                isReqImg: true,
                act: "admin_insert",
                arrGroup: [],
                arrMenu: [],
                isRead: false,
                Title: "Tambah Admin"
            }
        }

        async componentDidMount() {
            ReactDOM.render(<div className='btn-kembali' onClick={() => App()}><spam className="material-icons">arrow_back</spam> Kembali</div>, document.getElementById('divHeaderLabel'));
            let sql = await api("admin_detail", { iddata: this.state.id, token: getCookie("Token") });
            let tmpGroup = [], tmpMenu = [];
            for (let gp of sql.menu) {
                if (gp.IsGroup == 1) {
                    tmpGroup.push(gp);
                } else {
                    tmpMenu.push(gp);
                }
            }
            this.setState({
                arrGroup: tmpGroup,
                arrMenu: tmpMenu
            });

            if (this.state.id != "") {
                this.setState({
                    KodeAdmin: sql.data.KodeAdmin,
                    Nama: sql.data.Nama,
                    Telp: sql.data.Telp,
                    Alamat: sql.data.Alamat,
                    Email: sql.data.Email,
                    Password: "",
                    rePassword: "",
                    changeImg: 'no',
                    isReqImg: false,
                    act: "admin_edit",
                    isRead: true,
                    Title: "Edit Admin"
                });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        handleChk(e) {
            let menu = this.state.arrMenu;
            let tmpMenu = [];
            let FormCode = e.target.value;
            if (e.target.checked == true) {
                for (let mm of menu) {
                    if (mm.FormCode == FormCode) mm.Status = 1;
                    tmpMenu.push(mm);
                }
            } else {
                for (let mm of menu) {
                    if (mm.FormCode == FormCode) mm.Status = "false";
                    tmpMenu.push(mm);
                }
            }
            this.setState({ arrMenu: tmpMenu });
        }

        pilihSemua(e, group) {
            let tmpAkses = [];
            let tmpMenu = this.state.arrMenu;
            for (let mm of tmpMenu) {
                if (mm.GroupID == group) {
                    mm.Status = e.target.checked == true ? 1 : 'false';
                    tmpAkses.push(mm);
                } else {
                    tmpAkses.push(mm);
                }
            }
            this.setState({ arrMenu: tmpAkses });
        }

        render() {
            return (
                <Fragment>
                    <form id='iniForm' onSubmit={(e) => submitForm(e, { act: this.state.act, reload: true })} className="needs-validation" noValidate>
                        <div className='card-form'>
                            <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>{this.state.Title}</h2>
                            <input type="hidden" name="iddata" value={this.state.id} />
                            <input type="hidden" name="token" value={getCookie("Token")} />
                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label>Kode Admin</label>
                                        <input type="text" name="KodeAdmin" className="form-control" id="edtUserName" placeholder='Masukan Kode admin' value={this.state.KodeAdmin} onChange={(e) => this.handleChange(e, "KodeAdmin")} required />
                                        <div className="invalid-feedback">Silahkan Isi Kode Admin</div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label>Nama Admin</label>
                                        <input type="text" name="Nama" className="form-control" id="edtName" placeholder='Masukan Nama Admin' value={this.state.Nama} onChange={(e) => this.handleChange(e, "Nama")} required />
                                        <div className="invalid-feedback">Silahkan masukan anma admin</div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label>Telp</label>
                                        <input type="number" name="Telp" className="form-control" id="edtTelp" placeholder='Masukan Telp Admin' value={this.state.Telp} onChange={(e) => this.handleChange(e, "Telp")} required />
                                        <div className="invalid-feedback">Silahkan isi telp admin</div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="text" name="Email" className="form-control" id="edtEmail" placeholder='masukan Alamat Email' value={this.state.Email} onChange={(e) => this.handleChange(e, "Email")} required />
                                        <div className="invalid-feedback">Silahkan isi email admin</div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="password" name="Password" className="form-control" id="edtTelp" placeholder='Masukan password' value={this.state.Password} onChange={(e) => this.handleChange(e, "Password")} required readOnly={this.state.isRead} />
                                        <div className="invalid-feedback">Silahkan isi Password</div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label>Ulangi Password</label>
                                        <input type="password" name="rePassword" className="form-control" placeholder='ulangi Password' value={this.state.rePassword} onChange={(e) => this.handleChange(e, "rePassword")} required readOnly={this.state.isRead} />
                                        <div className="invalid-feedback">Silahkan ulangi password</div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Alamat</label>
                                <textarea name="Alamat" className="form-control" id="edtAlamat" placeholder='Masukan Alamat Admin' value={this.state.Alamat} onChange={(e) => this.handleChange(e, "Alamat")} required />
                                <div className="invalid-feedback">Silahkan isi alamat admin</div>
                            </div>
                            <p></p>
                            <h4 style={{ fontSize: "14px" }}>Hak Akses Menu</h4>
                            <p></p>
                            <div className='d-flex justify-content-start align-items-top gap-3'>
                                <div className="card" style={{ width: "200px" }}>
                                    <div className="card-header" style={{ backgroundColor: "#FFFFFF" }}>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="menu" id="menuAll" onChange={(e) => this.pilihSemua(e, '0')} />
                                            <label className="form-check-label" htmlFor="menuAll" style={{ fontSize: "14px" }}>
                                                Pilih Semua
                                            </label>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {
                                            this.state.arrMenu.map((menu, m) => {
                                                if (menu.GroupID == 0) {
                                                    let chk = menu.Status != "false" ? "checked" : "";
                                                    return (
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="menu" value={menu.FormCode} id={menu.ID} onChange={(e) => this.handleChk(e)} checked={chk} />
                                                            <label className="form-check-label" htmlFor={menu.ID}>
                                                                {menu.FormName}
                                                            </label>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                                {
                                    this.state.arrGroup.map((val, i) => {
                                        return (
                                            <div className="card" style={{ width: "200px" }}>
                                                <div className="card-header" style={{ backgroundColor: "#FFFFFF" }}>
                                                    <span style={{ fontSize: "14px" }}>{val.FormName}</span>
                                                </div>
                                                <div className="card-header" style={{ backgroundColor: "#FFFFFF" }}>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="menu" id={"menuAll" + val.ID} onChange={(e) => this.pilihSemua(e, val.ID)} />
                                                        <label className="form-check-label" htmlFor={"menuAll" + val.ID} style={{ fontSize: "14px" }}>
                                                            Pilih Semua
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    {
                                                        this.state.arrMenu.map((menu, m) => {
                                                            if (menu.GroupID == val.ID) {
                                                                let chk = menu.Status != "false" ? "checked" : "";
                                                                return (
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" name="menu" value={menu.FormCode} id={menu.ID} onChange={(e) => this.handleChk(e)} checked={chk} />
                                                                        <label className="form-check-label" htmlFor={menu.ID}>
                                                                            {menu.FormName}
                                                                        </label>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <p></p>
                            <button type="submit" className='btn btn-default'><span></span> Simpan</button>
                        </div>
                    </form>
                </Fragment>
            )
        }
    }
    ReactDOM.render(<Form />, document.getElementById('tampil'));
}

export default App;