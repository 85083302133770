import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { saiki, api, loading, rendModal, rendTable, getCookie, submitForm } from "../modul";
import img_upload from '../bg_uppload-image.png';

function App() {
    document.title = "Penggalangan Dana";
    var typingTimer;
    var delay = 500;

    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NamaKategori",
                by: "ASC",
                idlocation: 0
            };
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            clearTimeout(typingTimer);
            typingTimer = setTimeout(async () => {
                this.main();
            }, delay);
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => { this.main(); }, 100);
        }

        modalDelete(ini) {
            let dom = (
                <Fragment>
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <input type="hidden" name="token" value={getCookie("Token")} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </Fragment>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "kategori_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        modalForm(ini) {
            let dom = (
                <Fragment>
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </Fragment>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "employee_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        async main() {
            let param = {
                search: this.state.search,
                index: this.state.index,
                searchcount: this.state.searchcount,
                token: getCookie("Token"),
                sort: this.state.sort,
                by: this.state.by
            }
            let sql = await api("kategori_browse", param);
            console.log(sql.data);
            let field = {
                thead: [
                    { cap: "", sort: "", type: "opsi", width: "100px" },
                    { cap: "Icon", sort: "Gambar", width: "76px", type: "img" },
                    { cap: "Kode", sort: "KodeKategori", width: "100px", type: "str" },
                    { cap: "Nama Kategori", sort: "NamaKategori", width: "150px", type: "str" },
                    { cap: "Keterangan", sort: "Catatan", type: "str" }
                ],
                j: sql.ct
            }
            rendTable({
                dataset: { data: sql.data, field: field },
                div: "divView",
                table: { cls: "table  " },
                thead: { cls: "thead-dark" },
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                tbody: {
                    id: "tbodyDataBooking",
                    opsi: [
                        { type: "edit", event: (e) => rendForm(e) },
                        { type: "delete", event: (e) => this.modalDelete(e) },
                    ]
                },
                paggination: true
            });
        }

        componentDidMount() {
            ReactDOM.render("", document.getElementById('divHeaderLabel'));
            this.main();
            loading();
        }

        render() {
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Mater Kategori</h2>
                        <div className='row'>
                            <div className='col-md-9'>
                                <button className="btn btn-default" onClick={(e) => rendForm(e)}>+ Tambah</button>
                            </div>
                            <div className='col-md-3'>
                                <div className='input-icons'>
                                    <span className='material-icons icon-search'>search</span>
                                    <input className="form-control search-form" type="search" name="search" placeholder="Search" value={this.state.search} onChange={(e) => this.handleChange(e, "search")} required />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView"></div>
                    </div>
                    <nav id="paggination" style={{ paddingRight: "50px" }}></nav>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                KodeKategori: "",
                NamaKategori: "",
                Catatan: "",
                Gambar: "",
                isReqImg: true,
                changeImg: false,
                Title: "Tambah Kategori",
                IsLock: false
            }
        }

        async componentDidMount() {
            ReactDOM.render(<div className='btn-kembali' onClick={() => App()}><spam className="material-icons">arrow_back</spam> Kembali</div>, document.getElementById('divHeaderLabel'));
            if (this.state.id !== "") {
                let sql = await api("kategori_detail", { iddata: this.state.id, token: getCookie("Token") });
                console.log(sql);
                this.setState({
                    KodeKategori: sql.data.KodeKategori,
                    NamaKategori: sql.data.NamaKategori,
                    Catatan: sql.data.Catatan ? sql.data.Catatan.replaceAll("<br>", '\n') : "",
                    Gambar: sql.data.Gambar,
                    isReqImg: false,
                    Title: "Edit Kategori",
                    IsLock: sql.data.isLock == 1 ? true : false
                });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        handleCheck(e) {
            this.setState({ IsApproval: e.checked });
        }

        openFile(e) {
            document.getElementById("labelImg").innerHTML = '';
            let files = e.target.files;
            let file;
            for (let i = 0; i < files.length; i++) {
                let reader = new FileReader();
                file = files[i];
                reader.onload = (file) => {
                    document.getElementById("labelImg").innerHTML += `<img src="${reader.result}" width="256px" height="159px">`;
                    this.setState({ changeImg: "yes" });
                }
                reader.readAsDataURL(file)
            }
        }

        handleCheck(e) {
            if (e.target.checked == true) {
                this.setState({ IsLock: true });
            } else {
                this.setState({ IsLock: false });
            }
        }

        render() {
            let divImg = (
                <img src={img_upload} width="256px" height="159px" />
            );
            if (this.state.Gambar != "") {
                let host = window.location.hostname === "localhost" ? "http://localhost:2005/" : "https://api.srisannano.com/";
                divImg = <img src={host + "file/" + this.state.Gambar} width="256px" height="159px" />
            }
            let act = this.state.id ? "kategori_edit" : "kategori_insert";
            return (
                <Fragment>
                    <form id='iniForm' onSubmit={(e) => submitForm(e, { act: act, reload: true })} className="needs-validation" noValidate>
                        <div className='card-form'>
                            <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>{this.state.Title}</h2>
                            <input type="hidden" name="iddata" value={this.state.id} />
                            <input type="hidden" name="token" value={getCookie("Token")} />
                            <input type="hidden" name="changeImg" value={this.state.changeImg} />
                            <div className="form-group mb-3">
                                <label>Kode</label>
                                <input type="text" name="KodeKategori" className="form-control" placeholder='masukan kode kategori' value={this.state.KodeKategori} onChange={(e) => this.handleChange(e, "KodeKategori")} required />
                                <div className="invalid-feedback">Silahkan masukan kode kategori</div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Nama</label>
                                <input type="text" name="NamaKategori" className="form-control" placeholder='masukan nama kategori' value={this.state.NamaKategori} onChange={(e) => this.handleChange(e, "NamaKategori")} required />
                                <div className="invalid-feedback">Silahkan masukan nama kategori</div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Catatan</label>
                                <textarea name="Catatan" className="form-control" placeholder='Masukan Catatan' id="edtCatatan" value={this.state.Catatan} onChange={(e) => this.handleChange(e, "Catatan")} />
                            </div>
                            <div className="form-check form-switch">
                                <input className="form-check-input" name="IsLock" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={(e) => this.handleCheck(e)} checked={this.state.IsLock} />
                                <label className="form-check-label" for="flexSwitchCheckDefault">Tampilkan di landingpage</label>
                            </div>
                            <div className="form-group">
                                <label>Upload Logo</label>
                                <div className='image-upload'>
                                    <label id="labelImg" for="image-upload">
                                        {divImg}
                                    </label>
                                    <input type="file" accept='image/*' name="Img" id="image-upload" onChange={(e) => this.openFile(e)} required={this.state.isReqImg} />
                                    <div className="invalid-feedback">Silahkan pilih Gambar</div>
                                </div>
                            </div>
                            <p></p>
                            <button type="submit" className='btn  btn-default'><span></span> Simpan</button>
                        </div>
                    </form>
                </Fragment>
            )
        }
    }

    let id = ini.target.dataset.id,
        act = id !== undefined ? "employee_edit" : "employee_insert";

    ReactDOM.render(<Form />, document.getElementById('tampil'));

    // document.getElementById('iniForm').addEventListener("submit", function (e) {
    //     submitForm(e, { act: act, reload: true, modal: "btnCloseModalForm" });
    // });
}
export default App;