import '../lp.css';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { api, pesan, getCookie, submitForm } from '../modul.js';

const host = window.location.hostname === "localhost" ? "http://localhost:2005/api_mobile/" : "https://api.srisannano.com/api_mobile/";
const hostFile = window.location.hostname === "localhost" ? "http://localhost:2005/file/" : "https://api.srisannano.com/file/";
function App() {
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                BannerHeader: "",
            }
        }

        async componentDidMount() {
            if (this.state.id !== "") {
                let sql = await api("data_landingpage", { token: getCookie("Token") });
                this.setState({
                    BannerHeader: sql.data.BannerHeader,
                });
            }
        }

        render() {
            return (
                <Fragment>
                    <form id='iniForm' onSubmit={(e) => submitForm(e, { act: "edit_landingpage", reload: true })} className="needs-validation" noValidate>
                        <div className='card-form'>
                            <h3 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>{this.state.Title}</h3>
                            <input type="hidden" name="ID" value={this.state.id} />
                            <input type="hidden" name="token" value={getCookie("Token")} />
                            <div className="form-group mb-3">
                                <label>Header</label>
                                <textarea name="BannerHeader" className="form-control" placeholder='tuliskan Alamat' value={this.state.BannerHeader} onChange={(e) => this.setState({ BannerHeader: e.target.value })} required />
                                <div className="invalid-feedback">Silahkan Masukan Konten</div>
                            </div>
                            <p></p>
                            <button type="submit" className='btn  btn-default'><span></span> Simpan</button>
                        </div>
                    </form>
                </Fragment>
            )
        }
    }
    ReactDOM.render(<Main />, document.getElementById('tampil'));
}

export default App;
