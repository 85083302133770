import React, { Fragment } from 'react';
import "../Login.css";
import { setCookie, loading, pesan } from "../modul";
import logo from '../logo.png';
import login from '../login.png';

function App() {
    document.title = "Halaman Login";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = { username: '', pwd: "", };
            this.handleSubmit = this.handleSubmit.bind(this);
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        componentDidMount() {
            loading();
        }

        handleSubmit(event) {
            event.preventDefault();
            let btn = document.getElementById("btnLogin");
            let form = document.getElementById('iniForm');
            let data = new FormData(form);
            let host = window.location.hostname === "localhost" ? "http://localhost:2005/api_mobile/" : "https://api.srisannano.com/api_mobile/";
            fetch(host + "admin_login", {
                method: 'POST',
                body: data,
            }).then(response => response.json()).then(hasil => {
                if (hasil.status == "true") {
                    setCookie("Token", hasil.token, 30);
                    setCookie("NamaAdmin", hasil.NamaAdmin, 30);
                    window.location.href = "./";
                } else {
                    alert(hasil.message);
                    btn.innerHTML = "Login";
                    btn.disabled = false;
                }
            }).catch((error) => {
                console.log("Error: " + error);
                btn.innerHTML = "Login";
                btn.disabled = false;
            });
        }

        render() {
            return (
                <Fragment>
                    <div className='row'>
                        <div className='col-md-8 mb-1 container-login'>
                            <div className='bg-login'>
                                <div style={{ position: "absolute", left: "30px" }}>
                                    <img src={logo} alt="logo" className='logo-login' style={{ width: "150px" }} />
                                </div>
                                <p></p>
                                <div>
                                    <img src={login} alt="login" className='login' style={{width:"90%"}} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 mb-1'>
                            <div className="card-login">
                                <h3 style={{ lineHeight: "30px", fontSize: "20px" }}>Login Admin</h3>
                                <h6 style={{ lineHeight: "18px", fontSize: "12px" }}>Selamat datang kembali</h6>
                                <form id="iniForm" onSubmit={this.handleSubmit}>
                                    <div className='input-icons'>
                                        <span className='material-icons icon'>person</span>
                                        <input className="form-control login-form" type="text" name="username" placeholder="Username" value={this.state.username} onChange={(e) => this.handleChange(e, "username")} required />
                                    </div>
                                    <div className='input-icons'>
                                        <span className='material-icons icon'>lock</span>
                                        <input className="form-control login-form" type="password" name="password" placeholder="Password" value={this.state.pwd} onChange={(e) => this.handleChange(e, "pwd")} required />
                                    </div>
                                    <p></p>
                                    <button type="submit" className=" btn w-100 btn-login" id="btnLogin">Login</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    return (<Main />);
}

export default App;