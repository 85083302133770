import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { saiki, api, loading, rendModal, rendTable, getCookie, submitForm, numberFormat, pesan } from "../modul";
import img_upload from '../bg_uppload-image.png';
import img_unavailable from '../img-unavailable.png';
import img_contoh from '../Taglokasi.png';

function App() {
    document.title = "Event";
    var typingTimer;
    var delay = 500;
    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "TanggalBerakhir",
                by: "ASC",
                totalDraft: 0,
                totalOnGoing: 0,
                totalEnd: 0,
                status: "Berlangsung",
                ID: "",
                Judul: "",
                Pesan: "",
                TanggalBuat: saiki(),
                TypePesan: "all",
                arrUmat: [],
                dataUmat: []
            };
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleDataDetail(ini, mode = "input") {
            let id = mode == "input" ? ini.target.dataset.id : ini;
            rendFormEvent(id);
        }

        modalDelete(ini) {
            let dom = (
                <Fragment>
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <input type="hidden" name="token" value={getCookie("Token")} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </Fragment>
            );

            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });

            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "event_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        async main() {
            let param = {
                search: this.state.search,
                index: this.state.index,
                searchcount: this.state.searchcount,
                token: getCookie("Token"),
                sort: this.state.sort,
                by: this.state.by,
                status: this.state.status
            }
            let sql = await api("event_browse", param);

            this.setState({ totalDraft: sql.draft, totalOnGoing: sql.berlangsung, totalEnd: sql.berakhir });

            let field = {
                thead: [
                    { cap: "", sort: "", type: "opsi", width: "120px" },
                    { cap: "Image", sort: "Url", type: "img" },
                    { cap: "Judul", sort: "NamaEvent", type: "str" },
                    { cap: "Tanggal Acara", sort: "TanggalBerakhir" },
                    { cap: "Penggalang Dana", sort: "NamaCampaign", type: "str" },
                    { cap: "Lokasi", sort: "NamaLokasi", type: "str" },
                    { cap: "Donasi", sort: "TotalDonasi", type: "str" },
                    { cap: "Batas Hari", sort: "BatasHari", type: "str" },
                    { cap: "Status", sort: "State", type: "html" },
                    { cap: "", sort: "SisaHari", type: "html" }
                ],
                j: sql.ct
            }

            rendTable({
                dataset: { data: sql.data, field: field },
                div: "divView",
                table: { cls: "table  " },
                thead: { cls: "thead-dark" },
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                tbody: {
                    id: "tbodyDataBooking",
                    opsi: [
                        { type: "edit", event: (e) => rendForm(e) },
                        { type: "delete", event: (e) => this.modalDelete(e) },
                        { type: "other", icon: "more_horiz", event: (e) => this.handleDataDetail(e) },
                        { type: "other", icon: "send", event: (e) => this.handleBroadcast(e) },
                    ]
                },
                paggination: true
            });
        }

        componentDidMount() {
            ReactDOM.render("", document.getElementById('divHeaderLabel'));
            this.main();
            loading();
        }

        handleStatus(e, status) {
            this.setState({ status: status });
            setTimeout(() => {
                this.main();
            }, 200);
        }

        async handleBroadcast(e) {
            let ID = e.target.dataset.id;
            let sql = await api("event_detail", { iddata: ID, token: getCookie("Token") });
            if (sql.status == "true") {
                this.setState({ iddata: ID, Judul: sql.data.NamaEvent, Pesan: sql.data.Cerita, TanggalBuat: sql.data.TanggalBerakhir });
                const myModal = new window.bootstrap.Modal(document.getElementById('modalBroadCast'), {});
                myModal.show();
            }
        }

        handleCariUmat(val) {
            clearTimeout(typingTimer); // Hapus timer sebelumnya
            typingTimer = setTimeout(async () => {
                let sql = await api("donatur_browse", { search: val, token: getCookie("Token") });
                if (sql.status == "true") this.setState({ dataUmat: sql.data });
            }, delay);
        }

        handlePilih(item) {
            let data = this.state.arrUmat;
            data.push({ UserID: item.ID, Nama: item.Nama, Telepon: item.Telepon, Email: item.Email, TokenFirebase: item.TokenFirebase })
            this.setState({ arrUmat: data });
        }

        async handleSendBroadcast(e) {
            e.preventDefault();
            e.stopPropagation();
            if (e.target.checkValidity()) {
                let sql = await api("event_broadcast", { token: getCookie("Token"), iddata: this.state.iddata, Judul: this.state.Judul, Pesan: this.state.Pesan, TypePesan: this.state.TypePesan, TanggalBuat: this.state.TanggalBuat, UserID: JSON.stringify(this.state.arrUmat) });
                if (sql.status == "true") {
                    document.getElementById('btnTutupModal').click();
                    pesan("Pemberitahuan", sql.message, "primary");
                    this.main();
                } else {
                    pesan("Pemberitahuan", sql.message, "danger");
                }
            } else {
                let forms = document.getElementsByClassName('needs-validation');
                let ululu = Array.prototype.filter.call(forms, function (form) {
                    form.classList.add('was-validated');
                });
            }
        }

        render() {
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Event</h2>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card card-filter shadow-tipis" style={{ borderRadius: "12px", cursor: "pointer", border: "none" }} onClick={(e) => this.handleStatus(e, "Draft")}>
                                    <div className="card-body" style={{ minHeight: "80px", textAlign: "left", borderTop: "solid 10px #57EAE1", borderRadius: "12px" }}>
                                        <span className='material-icons' style={{ color: "#57EAE1", backgroundColor: "#C4F8F4", padding: "8px", borderRadius: "8px" }}>calendar_month</span>
                                        <br></br>
                                        <span style={{ fontSize: "16px" }}>Draft</span>
                                        <h1 style={{ fontSize: "32px", color: "#404040" }}>{this.state.totalDraft} Event</h1>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="card card-filter shadow" style={{ borderRadius: "12px", cursor: "pointer", border: "none" }} onClick={(e) => this.handleStatus(e, "Berlangsung")}>
                                    <div className="card-body" style={{ minHeight: "80px", textAlign: "left", borderTop: "solid 10px #57EAE1", borderRadius: "12px" }}>
                                        <span className='material-icons' style={{ color: "#57EAE1", backgroundColor: "#C4F8F4", padding: "8px", borderRadius: "8px" }}>calendar_month</span>
                                        <br></br>
                                        <span style={{ fontSize: "16px" }}>Berlangsung</span>
                                        <h1 style={{ fontSize: "32px", color: "#404040" }}>{this.state.totalOnGoing} Event</h1>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="card card-filter shadow-tipis" style={{ borderRadius: "12px", cursor: "pointer", border: "none" }} onClick={(e) => this.handleStatus(e, "Berakhir")}>
                                    <div className="card-body" style={{ minHeight: "80px", textAlign: "left", borderTop: "solid 10px #57EAE1", borderRadius: "12px" }}>
                                        <span className='material-icons' style={{ color: "#57EAE1", backgroundColor: "#C4F8F4", padding: "8px", borderRadius: "8px" }}>calendar_month</span>
                                        <br></br>
                                        <span style={{ fontSize: "16px" }}>Berakhir</span>
                                        <h1 style={{ fontSize: "32px", color: "#404040" }}>{this.state.totalEnd} Event</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <button className="btn btn-sm btn-default" onClick={(e) => rendForm(e)}>+ Tambah</button>
                        <p></p>
                        <div className="table-responsive" id="divView"></div>
                    </div>
                    <nav id="paggination" style={{ paddingRight: "50px" }}></nav>

                    <div className="modal fade" id="modalBroadCast" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <form onSubmit={(e) => this.handleSendBroadcast(e)} className='needs-validation' noValidate>
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Broadcast Acara</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <input type="hidden" name="iddata" value={this.state.id} />
                                        <input type="hidden" name="token" value={getCookie("Token")} />
                                        <div className="form-group mb-3">
                                            <label>Judul</label>
                                            <input type="text" name="Judul" className="form-control" placeholder='masukan nama Banner' value={this.state.Judul} onChange={(e) => this.handleChange(e, "Judul")} required />
                                            <div className="invalid-feedback">Silahkan masukan Judul</div>
                                        </div>
                                        <div className="form-group mb-3">
                                            <label>Deskripsi</label>
                                            <textarea name="Pesan" className="form-control" placeholder='tuliskan deskripsi' value={this.state.Pesan} onChange={(e) => this.handleChange(e, "Pesan")} required />
                                            <div className="invalid-feedback">Silahkan tulikan deskripsi</div>
                                        </div>
                                        <div className="form-group mb-3">
                                            <label>Tanggal</label>
                                            <input type="date" name="TanggalBuat" className="form-control" placeholder='pilih tanggal buat' value={this.state.TanggalBuat} onChange={(e) => this.handleChange(e, "TanggalBuat")} required />
                                            <div className="invalid-feedback">Silahkan pilih tanggal buat</div>
                                        </div>
                                        <div className="form-group mb-3">
                                            <label>Jenis Target</label>
                                            <select className='form-select' name="TypePesan" value={this.state.TypePesan} onChange={(e) => this.setState({ TypePesan: e.target.value })} required>
                                                <option value="all">Semua User</option>
                                                <option value="user">User Tertentu</option>
                                            </select>
                                            <div className="invalid-feedback">Silahkan pilih type pesan</div>
                                        </div>
                                        <textarea value={JSON.stringify(this.state.arrUmat)} name="UserID" style={{ display: "none" }} />
                                        <div style={{ display: this.state.TypePesan == "all" ? "none" : "block" }}>
                                            <buttom type="button" className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#modalUmat" onClick={() => this.handleCariUmat("")}>Tambah Umah</buttom>
                                            <p></p>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th>Nama</th>
                                                        <th>Telp</th>
                                                        <th>Email</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.arrUmat.map((item, i) => {
                                                            if (item.TokenFirebase != "") {
                                                                return (<tr>
                                                                    <td>{item.Nama}</td>
                                                                    <td>{item.Telepon}</td>
                                                                    <td>{item.Email}</td>
                                                                </tr>)
                                                            }
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" id='btnTutupModal' data-bs-dismiss="modal">Batal</button>
                                        <button type="submit" className="btn btn-primary">Kirim</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalUmat" tabindex="-1" aria-labelledby="modalUmat" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Data Umat</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <input className='form-control' type="search" placeholder='Cari Nama Umat' onChange={(e) => this.handleCariUmat(e.target.value)} />
                                    <p></p>
                                    <div className='table-responsive'>
                                        <table className='table table-stripped' >
                                            <thead>
                                                <tr>
                                                    <th>Nama</th>
                                                    <th>Telp</th>
                                                    <th>Email</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.dataUmat.map((item, i) => {
                                                        if (item.TokenFirebase != "") {
                                                            return (
                                                                <tr key={i} onClick={() => this.handlePilih(item)} data-bs-dismiss="modal">
                                                                    <td>{item.Nama}</td>
                                                                    <td>{item.Telepon}</td>
                                                                    <td>{item.Email}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" id='btnBatalPwd' className="btn btn-secondary" data-bs-dismiss="modal">Batal</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                KodeEvent: "",
                NamaEvent: "",
                TanggalMulai: saiki(),
                TanggalBerakhir: saiki(),
                IDCampaign: "",
                PenanggungJawab: "",
                Telp: '',
                IDKategori: "",
                IDLokasi: '',
                Cerita: "",
                TargetDonasi: "0",
                LinkDokumentasi: "",
                Rekening: "",
                URL: "",
                TagLokasi: "",
                State: "Draft",
                changeImg: "no",
                dataCampaign: [],
                dataKategori: [],
                dataLokasi: [],
                isReqImg: true,
                IsPrioritas: false,
                Title: "Tambah Event",
            }
        }

        async componentDidMount() {
            ReactDOM.render(<div className='btn-kembali' onClick={() => App()}><spam className="material-icons">arrow_back</spam> Kembali</div>, document.getElementById('divHeaderLabel'));
            let sqlCampaign = await api("campaign_browse", { token: getCookie("Token") });
            let sqlKategori = await api("kategori_browse", { token: getCookie("Token") });
            let sqlLokasi = await api("lokasi_browse", { token: getCookie("Token") });
            this.setState({
                dataCampaign: sqlCampaign.data,
                dataKategori: sqlKategori.data,
                dataLokasi: sqlLokasi.data,
            })
            if (this.state.id !== "") {
                let sql = await api("event_detail", { iddata: this.state.id, token: getCookie("Token") });
                this.setState({
                    KodeEvent: sql.data.KodeEvent,
                    NamaEvent: sql.data.NamaEvent,
                    TanggalMulai: sql.data.TanggalMulai,
                    TanggalBerakhir: sql.data.TanggalBerakhir,
                    IDCampaign: sql.data.IDCampaign,
                    PenanggungJawab: sql.data.PenanggungJawab,
                    Telp: sql.data.Telp,
                    IDKategori: sql.data.IDKategori,
                    IDLokasi: sql.data.IDLokasi,
                    Cerita: sql.data.Cerita,
                    TargetDonasi: sql.data.TargetDonasi,
                    State: sql.data.State,
                    URL: sql.data.URL,
                    TagLokasi: sql.data.TagLokasi,
                    isReqImg: false,
                    IsPrioritas: sql.data.IsPrioritas,
                    LinkDokumentasi: sql.data.LinkDokumentasi,
                    Rekening: sql.data.Rekening,
                    Title: "Edit Event",
                });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        openFile(e) {
            document.getElementById("labelImg").innerHTML = '';
            let files = e.target.files;
            let file;
            for (let i = 0; i < files.length; i++) {
                let reader = new FileReader();
                file = files[i];
                reader.onload = (file) => {
                    document.getElementById("labelImg").innerHTML += `<img src="${reader.result}" width="256px" height="159px">`;
                    this.setState({ changeImg: "yes" });
                }
                reader.readAsDataURL(file)
            }
        }

        handleChk(e, ssts) {
            this.setState({ [ssts]: e.target.checked == true ? "1" : "0" });
        }

        render() {
            let divImg = (
                <img src={img_upload} width="256px" height="159px" />
            );
            if (this.state.URL != "") {
                let host = window.location.hostname === "localhost" ? "http://localhost:2005/" : "https://api.srisannano.com/";
                divImg = <img src={host + "file/" + this.state.URL} width="256px" height="159px" />
            }
            let act = this.state.id != "" ? "event_edit" : "event_insert"
            return (
                <Fragment>
                    <form id='iniForm' onSubmit={(e) => submitForm(e, { act: act, reload: true })} className="needs-validation" noValidate>
                        <div className='card-form'>
                            <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>{this.state.Title}</h2>
                            <input type="hidden" name="iddata" value={this.state.id} />
                            <input type="hidden" name="token" value={getCookie("Token")} />
                            <input type="hidden" name="changeImg" value={this.state.changeImg} />
                            <div className="form-group mb-3">
                                <label>Kode Event</label>
                                <input type="text" name="KodeEvent" className="form-control" placeholder='Masukan Kode Event' value={this.state.KodeEvent} onChange={(e) => this.handleChange(e, "KodeEvent")} required />
                                <div className="invalid-feedback">Silahkan masukan Kode event</div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Judul</label>
                                <input type="text" name="NamaEvent" className="form-control" placeholder='Masukan Nama Event' value={this.state.NamaEvent} onChange={(e) => this.handleChange(e, "NamaEvent")} required />
                                <div className="invalid-feedback">Silahkan masukan Nama event</div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Organisasi</label>
                                <select name="IDCampaign" className='form-select' value={this.state.IDCampaign} onChange={(e) => this.handleChange(e, "IDCampaign")} required>
                                    <option value="">Silahkan pilih organisasi</option>
                                    {this.state.dataCampaign.map(opt => <option value={opt.ID}>{opt.NamaCampaign}</option>)}
                                </select>
                                <div className="invalid-feedback">Silahkan pilih organisasi</div>
                            </div>
                            {/* <div className="form-group mb-3">
                                <label>Kategori Event</label>
                                <select name="IDKategori" className='form-select' value={this.state.IDKategori} onChange={(e) => this.handleChange(e, "IDKategori")} required>
                                    <option value="">Silahkan pilih Kategori event</option>
                                    {this.state.dataKategori.map(opt => <option value={opt.ID}>{opt.NamaKategori}</option>)}
                                </select>
                                <div className="invalid-feedback">Silahkan pilih nama kategori</div>
                            </div> */}
                            <div className="form-group mb-3">
                                <label>Nama Penanggung Jawab</label>
                                <input type="text" name="PenanggungJawab" className="form-control" placeholder='Masukan Nama Penanggung Jawab' value={this.state.PenanggungJawab} onChange={(e) => this.handleChange(e, "PenanggungJawab")} required />
                                <div className="invalid-feedback">Silahkan masukan Nama Penanggung Jawab</div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Nomor Telp</label>
                                <input type="number" name="Telp" className="form-control" placeholder='Masukan Nomor Telp' value={this.state.Telp} onChange={(e) => this.handleChange(e, "Telp")} required />
                                <div className="invalid-feedback">Silahkan masukan Nomor Telp</div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 mb-1'>
                                    <div className="form-group mb-3">
                                        <label>Lokasi</label>
                                        <select name="IDLokasi" className='form-select' value={this.state.IDLokasi} onChange={(e) => this.handleChange(e, "IDLokasi")} required>
                                            <option value="">Silahkan pilih Lokasi event</option>
                                            {this.state.dataLokasi.map(opt => <option value={opt.ID}>{opt.NamaLokasi}</option>)}
                                        </select>
                                        <div className="invalid-feedback">Silahkan pilih nama lokasi</div>
                                    </div>
                                </div>
                                <div className='col-md-6 mb-1'>
                                    <div className="form-group mb-3">
                                        <label>Link Dokumentasi</label>
                                        <input type="url" name="LinkDokumentasi" className="form-control" value={this.state.LinkDokumentasi} onChange={(e) => this.handleChange(e, "LinkDokumentasi")} />
                                        <div className="invalid-feedback">Silahkan Masukan link Dokumentasi</div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 mb-1'>
                                    <div className="form-group mb-3">
                                        <label>Tanggal Rilis</label>
                                        <input type="date" name="TanggalMulai" className="form-control" value={this.state.TanggalMulai} onChange={(e) => this.handleChange(e, "TanggalMulai")} required />
                                        <div className="invalid-feedback">Silahkan tentukan tanggal mulai</div>
                                    </div>
                                </div>
                                <div className='col-md-6 mb-1'>
                                    <div className="form-group mb-3">
                                        <label>Tanggal Acara</label>
                                        <input type="date" name="TanggalBerakhir" className="form-control" value={this.state.TanggalBerakhir} onChange={(e) => this.handleChange(e, "TanggalBerakhir")} required />
                                        <div className="invalid-feedback">Silahkan tentukan tanggal berakhir</div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 mb-1'>
                                    <div className='form-group'>
                                        <label>Nominal Donasi</label>
                                        <div className='input-group'>
                                            <button className='btn btn-secondary'>Rp</button>
                                            <input type="number" className='form-control' name="TargetDonasi" value={this.state.TargetDonasi} onChange={(e) => this.handleChange(e, "TargetDonasi")} required />
                                            <div className='invalid-feedback'>Silahkan tentukan target donasi</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 mb-1'>
                                    <div className='form-group'>
                                        <label>Rekening</label>
                                        <input type="text" name="Rekening" className="form-control" value={this.state.Rekening} onChange={(e) => this.handleChange(e, "Rekening")} />
                                        <div className="invalid-feedback">Silahkan tentukan tanggal berakhir</div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 mb-2'>
                                    <div className='form-group'>
                                        <label>Tag Lokasi</label>
                                        <div className='input-group'>
                                            <input type="text" name="TagLokasi" className="form-control" value={this.state.TagLokasi} onChange={(e) => this.handleChange(e, "TagLokasi")} />
                                            <button type='button' className='btn btn-secondary material-icons' data-bs-toggle="modal" data-bs-target="#exampleModal">info</button>
                                        </div>
                                        <div className="invalid-feedback">Silahkan masukan tag lokasi</div>
                                    </div>
                                </div>
                                <div className='col-md-6 mb-2'>
                                    <div className='form-group'>
                                        <label>Status Event</label>
                                        <select value={this.state.State} className='form-select' name="State" onChange={(e) => this.handleChange(e, "State")}>
                                            <option value="Draft">Draft</option>
                                            <option value="Berlangsung">Berlangsung</option>
                                            <option value="Terkumpul">Terkumpul</option>
                                            <option value="Berakhir">Berakhir</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-check form-switch">
                                <input className="form-check-input" name="IsPrioritas" value="1" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={this.state.IsPrioritas} onChange={(e) => (e, this.handleChk(e, "IsPrioritas"))} />
                                <label className="form-check-label" for="flexSwitchCheckDefault">Jadikan Prioritas</label>
                            </div>
                            <div className='form-group'>
                                <label>Gambar</label>
                                <div className='image-upload'>
                                    <label id="labelImg" for="image-upload">
                                        {divImg}
                                    </label>
                                    <input type="file" accept='image/*' name="Img" id="image-upload" onChange={(e) => this.openFile(e)} required={this.state.isReqImg} />
                                    <div className="invalid-feedback">Silahkan pilih Gambar</div>
                                </div>
                            </div>
                            <p></p>
                            <button type="submit" className='btn btn-default'><span></span> Simpan</button>
                        </div>
                    </form>

                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Tutorial Ambil</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h6>1. Silahkan masuk ke  <a href="https://maps.google.com/" target='__blank'>google maps</a></h6>
                                    <h6>2. Selanjutnya silahkan pilih lokasi atau tempat yang ingin anda ambil kordinatnya, lalu click kanan pada lokasi tersebut dan click kordinat yang muncul seperti gambar di bawah</h6>
                                    <img src={img_contoh} width="100%" />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Form />, document.getElementById('tampil'));
}

function rendFormEvent(ini, tab = "cerita") {
    class Form extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                id: ini || "",
                JudulCerita: "",
                EventID: ini,
                TanggalCerita: saiki(),
                Cerita: "",
                isReqImg: true,
                search: "",
                sort: "TanggalBuat",
                by: "DESC",
                index: 1,
                searchcount: 100,
                Gambar1: img_unavailable,
                NamaEvent: "",
                changeImg: "yes",
                NamaEvent: "",
                TargetDonasi: 0,
                JumlahTekumpul: 0,
                NominalTerpakai: 0,
                BatasHari: 0,
                isCerita: null,
                Url: "",
                imgFile: []
            }
        }

        async componentDidMount() {
            ReactDOM.render(<div className='btn-kembali' onClick={() => App()}><spam className="material-icons">arrow_back</spam> Kembali</div>, document.getElementById('divHeaderLabel'));
            let sql = await api("event_detail", { iddata: this.state.id, token: getCookie("Token") });
            console.log(sql);
            if (sql.data.JudulCerita) {
                this.setState({
                    JudulCerita: sql.data.JudulCerita,
                    TanggalCerita: sql.data.TanggalCerita,
                    Cerita: sql.data.Cerita ? sql.data.Cerita.replaceAll("<br>", '\n') : "",
                    Gambar1: sql.data.Gambar1,
                    Url: sql.data.Url,
                    isReqImg: false,
                    NamaEvent: sql.data.NamaEvent,
                    changeImg: "no",
                    TargetDonasi: sql.data.TargetDonasi,
                    JumlahTekumpul: sql.data.JumlahTekumpul,
                    NominalTerpakai: sql.data.NominalTerpakai,
                    BatasHari: sql.data.BatasHari,
                    isCerita: true,
                    imgFile: sql.file
                });
            } else {
                this.setState({ Url: sql.data.Url })
            }
            if (tab == "berita") {
                this.main();
                document.getElementById('pills-profile-tab').click();
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        modalDelete(ini) {
            rendModal({
                id: "modalDelete",
                body: <h5>Apakah Anda yakin akan menghapus data ini</h5>,
                title: "Hapus Data",
                frmID: "formDelete",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });

            let h = this;

            document.getElementById('formDelete').addEventListener("submit", async function (e) {
                e.stopPropagation();
                e.preventDefault();
                let sql = await api("berita_delete", { iddata: ini.target.dataset.id, token: getCookie("Token") });
                if (sql.status == "true") {
                    h.main();
                    pesan("Pemberitahuan", sql.message, "primary");
                    document.getElementById('btnCloseModalmodalDelete').click();
                } else {
                    pesan("Pemberitahuan", sql.message, "danger");
                }
            });
        }

        openFile(e) {
            document.getElementById("labelImg").innerHTML = '';
            let files = e.target.files;
            let file;
            for (let i = 0; i < files.length; i++) {
                let reader = new FileReader();
                file = files[i];
                reader.onload = (file) => {
                    document.getElementById("labelImg").innerHTML += `<img src="${reader.result}" width="256px" height="159px">`;
                    this.setState({ changeImg: "yes" });
                }
                reader.readAsDataURL(file)
            }
        }

        async main() {
            let param = {
                search: this.state.search,
                index: this.state.index,
                searchcount: this.state.searchcount,
                token: getCookie("Token"),
                sort: this.state.sort,
                by: this.state.by,
                EventID: this.state.EventID
            }

            let sql = await api("berita_browse", param);

            let field = {
                thead: [
                    { cap: "Image", sort: "Url", type: "img", width: "100px" },
                    { cap: "Judul Berita", sort: "JudulBerita" },
                    { cap: "Tanggal", sort: "Tanggal" },
                    { cap: "Kategori berita", sort: "NamaKategori" },
                    { cap: "Deskripsi", sort: "Berita", type: "html" },
                    { cap: "", sort: "", type: "opsi", width: "100px" },
                ],
                j: sql.ct
            }

            rendTable({
                dataset: { data: sql.data, field: field },
                div: "divViewBerita",
                table: { cls: "table  " },
                thead: { cls: "thead-dark" },
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                tbody: {
                    id: "tbodyDataBooking",
                    opsi: [
                        { type: "edit", event: (e) => rendFormTambahCerita(e, this.state.id) },
                        { type: "delete", event: (e) => this.modalDelete(e) },
                    ]
                },
                paggination: true
            });
        }

        handleSubmitCerita(e) {
            e.preventDefault();
            e.stopPropagation();
            let btn = document.getElementById('btnSubmitCerita');;
            let oldBtn = btn.innerHTML.trim();
            if (e.target.checkValidity()) {
                btn.innerHTML = `<span class="spinner-border spinner-border-sm"></span> ${oldBtn}`;
                btn.disabled = true;
                let data = new FormData(e.target);
                let host = window.location.hostname === "localhost" ? "http://localhost:2005/api_mobile/" : "https://api.srisannano.com/api_mobile/";
                let Url = `${host}event_cerita_edit`;
                fetch(Url, {
                    method: 'POST',
                    body: data,
                }).then(response => response.json()).then(hasil => {
                    if (hasil.status === "true") {
                        pesan("Proses Success", hasil.message, "primary");
                        rendFormEvent(ini, "cerita");
                    } else {
                        alert(hasil.message);
                        btn.innerHTML = oldBtn;
                        btn.disabled = false;
                    }
                }).catch((error) => {
                    console.log("Error: " + error);
                    btn.innerHTML = oldBtn;
                    btn.disabled = false;
                });
            } else {
                let forms = document.getElementsByClassName('needs-validation');
                let ululu = Array.prototype.filter.call(forms, function (form) {
                    form.classList.add('was-validated');
                });
            }
        }

        render() {
            let host = window.location.hostname === "localhost" ? "http://localhost:2005/" : "https://api.srisannano.com/";
            return (
                <Fragment>
                    <div className='card-form'>
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Event Cerita</h2>
                        <div className='d-flex justify-content-start align-items-top gap-2'>
                            <div id="carouselExample" className="carousel slide" data-bs-ride="true">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src={host + "file/" + this.state.Url} className="d-block w-100" alt="gambar" width="310px" height="190px" style={{ borderRadius: "12px" }} />
                                    </div>
                                    {
                                        this.state.imgFile.map((img, i) => {
                                            return (
                                                <div className="carousel-item" key={i}>
                                                    <img src={host + "file/" + img.NameFile} className="d-block w-100" alt="gambar" width="310px" height="190px" style={{ borderRadius: "12px" }} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                            <div>
                                <h3 style={{ fontSize: "20px" }}>{this.state.NamaEvent}</h3>
                                <table className='table' style={{ lineHeight: "12px" }}>
                                    <tr>
                                        <td style={{ height: "24px", paddingLeft: "0px", fontSize: "24px", }}>Target Donasi</td>
                                        <td style={{ height: "24px", paddingLeft: "0px", fontSize: "24px", }}>:</td>
                                        <td style={{ height: "24px", paddingLeft: "0px", fontSize: "24px", }}>{numberFormat(this.state.TargetDonasi)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: "24px", paddingLeft: "0px" }}>Jumlah Terkumpul</td>
                                        <td style={{ height: "24px", paddingLeft: "0px" }}>:</td>
                                        <td style={{ height: "24px", paddingLeft: "0px" }}>{numberFormat(this.state.JumlahTekumpul)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: "24px", paddingLeft: "0px" }}>Nominal Terpakai</td>
                                        <td style={{ height: "24px", paddingLeft: "0px" }}>:</td>
                                        <td style={{ height: "24px", paddingLeft: "0px" }}>{numberFormat(this.state.NominalTerpakai)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: "24px", paddingLeft: "0px" }}>Batas Hari</td>
                                        <td style={{ height: "24px", paddingLeft: "0px" }}>:</td>
                                        <td style={{ height: "24px", paddingLeft: "0px" }}>{this.state.BatasHari}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <p></p>
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link nav-link-kuning active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Cerita</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link nav-link-kuning" onClick={(e) => this.main(e)} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Berita Terbaru</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <form id='iniForm' onSubmit={(e) => this.handleSubmitCerita(e)} className="needs-validation" noValidate>
                                    <input type="hidden" name="iddata" value={this.state.id} />
                                    <input type="hidden" name="token" value={getCookie("Token")} />
                                    <input type="hidden" name="EventID" value={this.state.EventID} />
                                    <input type="hidden" name="changeImg" value={this.state.changeImg} />
                                    <div className='form-group'>
                                        <label>Judul Cerita</label>
                                        <input type="text" name="JudulCerita" className='form-control' placeholder='Masukan Judul Cerita' value={this.state.JudulCerita} onChange={(e) => this.handleChange(e, "JudulCerita")} required />
                                        <div className='invalid-feedback'>Silahakan Masukan Judul Cerita</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Tanggal</label>
                                        <input type="date" name="TanggalCerita" className='form-control' value={this.state.TanggalCerita} onChange={(e) => this.handleChange(e, "TanggalCerita")} required />
                                        <div className='invalid-feedback'>Silahakan Pilh Tanggal</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Deskripsi</label>
                                        <textarea name="Cerita" className='form-control' value={this.state.Cerita} onChange={(e) => this.setState({ Cerita: e.target.value })} />
                                        <div className='invalid-feedback'>Silahakan Tuliskan Cerita</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Gambar</label>
                                        <div className='image-upload'>
                                            <label id="labelImg" for="image-upload">
                                                <img src={img_upload} width="256px" height="159px" />
                                            </label>
                                            <input type="file" accept='image/*' name="Img" id="image-upload" onChange={(e) => this.openFile(e)} required={this.state.isReqImg} />
                                            <div className="invalid-feedback">Silahkan pilih Gambar</div>
                                        </div>
                                    </div>
                                    <p></p>
                                    <button type="submit" id="btnSubmitCerita" className='btn btn-default'><span></span> Simpan</button>
                                </form>
                            </div>
                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <div className='row'>
                                    <div className='col-md-9'>
                                        <button className="btn btn-default" onClick={(e) => rendFormTambahCerita(e, this.state.id)}>+ Tambah</button>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='input-icons'>
                                            <span className='material-icons icon'>search</span>
                                            <input className="form-control login-form" type="search" name="search" placeholder="Search" value={this.state.search} onChange={(e) => this.handleChange(e, "search")} required />
                                        </div>
                                    </div>
                                </div>
                                <div className='table-responsive' id="divViewBerita"></div>
                                <nav id="paggination" style={{ paddingRight: "50px" }}></nav>
                            </div>
                        </div>
                    </div>
                </Fragment >
            )
        }
    }
    ReactDOM.render(<Form />, document.getElementById('tampil'));
}

function rendFormTambahCerita(ini, EventID) {
    class Form extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                EventID: EventID,
                Tanggal: saiki(),
                JudulBerita: "",
                Berita: "",
                Url: "",
                isReqImg: true,
                Title: "Tambah Berita Terbaru",
                changeImg: 'yes'
            }
        }

        async componentDidMount() {
            ReactDOM.render(<div className='btn-kembali' onClick={() => rendFormEvent(EventID, "berita")}><spam className="material-icons">arrow_back</spam> Kembali</div>, document.getElementById('divHeaderLabel'));
            if (this.state.id !== "") {
                let sql = await api("berita_detail", { iddata: this.state.id, token: getCookie("Token") });
                this.setState({
                    Tanggal: sql.data.Tanggal,
                    JudulBerita: sql.data.JudulBerita,
                    Berita: sql.data.Berita ? sql.data.Berita.replaceAll("<br>", '\n') : "",
                    Url: sql.data.Url,
                    isReqImg: false,
                    Title: "Edit Berita Terbaru",
                    changeImg: "no"
                });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        openFile(e) {
            document.getElementById("labelImg").innerHTML = '';
            let files = e.target.files;
            let file;
            for (let i = 0; i < files.length; i++) {
                let reader = new FileReader();
                file = files[i];
                reader.onload = (file) => {
                    document.getElementById("labelImg").innerHTML += `<img src="${reader.result}" width="256px" height="159px">`;
                    this.setState({ changeImg: "yes" });
                }
                reader.readAsDataURL(file)
            }
        }

        handleSubmitBerita(e) {
            e.preventDefault();
            e.stopPropagation();
            let btn = document.getElementById('btnSaveBerita');;
            let oldBtn = btn.innerHTML.trim();
            if (e.target.checkValidity()) {
                btn.innerHTML = `<span class="spinner-border spinner-border-sm"></span> ${oldBtn}`;
                btn.disabled = true;
                let data = new FormData(e.target);
                let host = window.location.hostname === "localhost" ? "http://localhost:2005/api_mobile/" : "https://api.srisannano.com/api_mobile/";
                let Url = `${host}${this.state.id != "" ? "berita_edit" : "berita_insert"}`;
                fetch(Url, {
                    method: 'POST',
                    body: data,
                }).then(response => response.json()).then(hasil => {
                    if (hasil.status === "true") {
                        pesan("Proses Success", hasil.message, "primary");
                        rendFormEvent(EventID, "berita");
                    } else {
                        alert(hasil.message);
                        btn.innerHTML = oldBtn;
                        btn.disabled = false;
                    }
                }).catch((error) => {
                    console.log("Error: " + error);
                    btn.innerHTML = oldBtn;
                    btn.disabled = false;
                });
            } else {
                let forms = document.getElementsByClassName('needs-validation');
                let ululu = Array.prototype.filter.call(forms, function (form) {
                    form.classList.add('was-validated');
                });
            }
        }

        render() {
            let divImg = (
                <img src={img_upload} width="256px" height="159px" />
            );
            if (this.state.Url != "") {
                let host = window.location.hostname === "localhost" ? "http://localhost:2005/" : "https://api.srisannano.com/";
                divImg = <img src={host + "file/" + this.state.Url} width="256px" height="159px" />
            }
            return (
                <Fragment>
                    <form id='iniForm' onSubmit={(e) => this.handleSubmitBerita(e)} className="needs-validation" noValidate>
                        <div className='card-form'>
                            <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>{this.state.Title}</h2>
                            <input type="hidden" name="iddata" value={this.state.id} />
                            <input type="hidden" name="token" value={getCookie("Token")} />
                            <input type="hidden" name="EventID" value={EventID} />
                            <input type="hidden" name="changeImg" value={this.state.changeImg} />
                            <div className='form-group'>
                                <label>Judul Berita</label>
                                <input type="text" name="JudulBerita" className='form-control' placeholder='Masukan Judul Berita' value={this.state.JudulBerita} onChange={(e) => this.handleChange(e, "JudulBerita")} />
                                <div className='invalid-feedback'>Silahakan Masukan Judul Berita</div>
                            </div>
                            <div className='form-group'>
                                <label>Tanggal</label>
                                <input type="date" name="Tanggal" className='form-control' value={this.state.Tanggal} onChange={(e) => this.handleChange(e, "Tanggal")} required />
                                <div className='invalid-feedback'>Silahakan Pilh Tanggal</div>
                            </div>
                            <div className='form-group'>
                                <label>Deskripsi</label>
                                <textarea name="Berita" className='form-control' value={this.state.Berita} onChange={(e) => this.setState({ Berita: e.target.value })} />
                                <div className='invalid-feedback'>Silahakan Tuliskan Berita</div>
                            </div>
                            <div className='form-group'>
                                <label>Gambar</label>
                                <div className='image-upload'>
                                    <label id="labelImg" for="image-upload">
                                        {divImg}
                                    </label>
                                    <input type="file" accept='image/*' name="Img" id="image-upload" onChange={(e) => this.openFile(e)} required={this.state.isReqImg} />
                                    <div className="invalid-feedback">Silahkan pilih Gambar</div>
                                </div>
                            </div>
                            <p></p>
                            <button type="submit" id='btnSaveBerita' className='btn  btn-default'><span></span> Simpan</button>
                        </div>
                    </form>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Form />, document.getElementById('tampil'));

}
export default App;