import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { pesan, api, loading, rendModal, rendTable, getCookie, submitForm } from "../modul";

function App(mode = "subarticle") {
    document.title = "Explore / Article";
    var typingTimer;
    var delay = 500;

    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: mode == "subarticle" ? "NameSubArticle" : "NameArticle",
                by: "ASC",
                idlocation: 0,
                mode: mode
            };
        }

        componentDidMount() {
            ReactDOM.render("", document.getElementById('divHeaderLabel'));
            this.main();
            loading();
            if (mode == "article") document.getElementById('pills-profile-tab').click();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            clearTimeout(typingTimer); // Hapus timer sebelumnya
            typingTimer = setTimeout(async () => {
                this.main();
            }, delay);
        }

        handlePage(e) {
            e.preventDefault();
            console.log(e.target);
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 200);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => { this.main(); }, 200);
        }

        modalDelete(ini) {
            let dom = (
                <Fragment>
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <input type="hidden" name="token" value={getCookie("Token")} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </Fragment>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            let ini2 = this;
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: ini2.state.mode == "subarticle" ? "article_sub_delete" : "article_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        handleMode(mode) {
            this.setState({ mode: mode, sort: mode == "subarticle" ? "NameSubArticle" : "NameArticle" });
            setTimeout(() => {
                this.main();
            }, 200);
        }

        handeLink(e) {
            let host = window.location.hostname === "localhost" ? "http://localhost:2005/" : "https://api.srisannano.com/";
            let link = e.target.innerText;
            window.open(host + "file/" + link);
        }

        async main() {
            let param = {
                search: this.state.search,
                index: this.state.index,
                searchcount: this.state.searchcount,
                token: getCookie("Token"),
                sort: this.state.sort,
                by: this.state.by,
                mode: this.state.mode
            }
            let sql = await api("article_browse", param);
            let field = {
                thead: [
                    { cap: "", sort: "", type: "opsi", width: "100px" },
                    { cap: "Judul", sort: "NameArticle", type: "str", width: "150px" },
                    { cap: "Link", sort: "Url", type: "link", width: "120px" },
                    { cap: "Tanggal", sort: "Tanggal", type: "str" },
                ],
                j: sql.ct,
                link: (e) => this.handeLink(e)
            }

            if (this.state.mode == "subarticle") {
                field = {
                    thead: [
                        { cap: "", sort: "", type: "opsi", width: "150px" },
                        { cap: "Judul", sort: "NameSubArticle", type: "str", width: "150px" },
                        { cap: "Total Artikel", sort: "CT", width: "120px" },
                        { cap: "Tanggal", sort: "Tanggal", type: "str" },
                    ],
                    j: sql.ct,
                    link: (e) => this.handeLink(e)
                }
            }

            rendTable({
                dataset: { data: sql.data, field: field },
                div: "divView",
                table: { cls: "table  " },
                thead: { cls: "thead-dark" },
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                tbody: {
                    id: "tbodyDataArticle",
                    opsi: [
                        { type: "edit", event: (e) => this.handleForm(e) },
                        { type: "delete", event: (e) => this.modalDelete(e) },
                    ]
                },
            });
        }

        handleForm(e = "") {
            if (this.state.mode == "article") {
                if (e != "") {
                    let ID = e.target.dataset.id;
                    ReactDOM.render(<FormArticle ID={ID} />, document.getElementById('tampil'));
                } else {
                    ReactDOM.render(<FormArticle ID="" />, document.getElementById('tampil'));
                }
            } else {
                if (e != "") {
                    let ID = e.target.dataset.id;
                    ReactDOM.render(<FormSubArticle ID={ID} />, document.getElementById('tampil'));
                } else {
                    ReactDOM.render(<FormSubArticle ID="" />, document.getElementById('tampil'));
                }
            }
        }

        render() {
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Explore Article</h2>
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link nav-link-kuning active" onClick={(e) => this.handleMode("subarticle")} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Kategori</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link nav-link-kuning" onClick={(e) => this.handleMode("article")} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Artikel</button>
                            </li>
                        </ul>
                        <div className='row'>
                            <div className='col-md-9'>
                                <button className="btn btn-default" onClick={(e) => this.handleForm()}>+ Tambah</button>
                            </div>
                            <div className='col-md-3'>
                                <div className='input-icons'>
                                    <span className='material-icons icon-search'>search</span>
                                    <input className="form-control search-form" type="search" name="search" placeholder="Search" value={this.state.search} onChange={(e) => this.handleChange(e, "search")} required />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView"></div>
                        <nav id="paggination" style={{ paddingRight: "50px" }}></nav>
                    </div>
                </Fragment>
            )
        }
    }

    class FormSubArticle extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                id: this.props.ID,
                NameSubArticle: "",
                Title: "Tambah Article",
                detail: [],
                dataArticle: []
            }
        }

        async componentDidMount() {
            ReactDOM.render(<div className='btn-kembali' onClick={() => App("subarticle")}><spam className="material-icons">arrow_back</spam> Kembali</div>, document.getElementById('divHeaderLabel'));
            if (this.state.id !== "") {
                let sql = await api("sub_article_detail", { iddata: this.props.ID, token: getCookie("Token") });
                this.setState({
                    NameSubArticle: sql.data.NameSubArticle,
                    Title: "Edit Article",
                    detail: sql.detail
                });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        async handleCariArticle(val) {
            let sql = await api("article_browse", { search: val, mode: "article", token: getCookie("Token") });
            if (sql.status == "true") this.setState({ dataArticle: sql.data });
        }

        handlePilihArticle(IDArticle, NameArticle) {
            let data = this.state.detail;
            let isTrue = true;
            for (let dd of data) if (dd.IDArticle == IDArticle) isTrue = false;
            if (isTrue == true) {
                data.push({ IDArticle: IDArticle, NameArticle: NameArticle });
                this.setState({ detail: data });
                document.getElementById('btnTutupModal').click();
            } else {
                pesan("Peringatan", "Artikel sudah dipilih", "danger");
            }
        }

        handleDeleteArticle(i) {
            let data = this.state.detail;
            let tmp = [];
            for (let dd in data) if (dd != i) tmp.push(data[dd]);
            this.setState({ detail: tmp });
        }

        handleSubmitForm(e) {
            e.preventDefault();
            e.stopPropagation();
            let btn = document.getElementById('btnSave');;
            let oldBtn = btn.innerHTML.trim();
            if (e.target.checkValidity()) {
                btn.innerHTML = `<span class="spinner-border spinner-border-sm"></span> ${oldBtn}`;
                btn.disabled = true;
                let data = new FormData(e.target);
                let host = window.location.hostname === "localhost" ? "http://localhost:2005/api_mobile/" : "https://api.srisannano.com/api_mobile/";
                let Url = `${host}${this.state.id ? "article_sub_edit" : "article_sub_insert"}`;
                fetch(Url, {
                    method: 'POST',
                    body: data,
                }).then(response => response.json()).then(hasil => {
                    if (hasil.status === "true") {
                        pesan("Proses Success", hasil.message, "primary");
                        App("subarticle");
                    } else {
                        alert(hasil.message);
                        btn.innerHTML = oldBtn;
                        btn.disabled = false;
                    }
                }).catch((error) => {
                    console.log("Error: " + error);
                    btn.innerHTML = oldBtn;
                    btn.disabled = false;
                });
            } else {
                let forms = document.getElementsByClassName('needs-validation');
                let ululu = Array.prototype.filter.call(forms, function (form) {
                    form.classList.add('was-validated');
                });
            }
        }

        render() {
            let act = this.state.id ? "article_sub_edit" : "article_sub_insert";
            return (
                <Fragment>
                    <form id='iniForm' onSubmit={(e) => this.handleSubmitForm(e)} className="needs-validation" noValidate>
                        <div className='card-form'>
                            <h3 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>{this.state.Title}</h3>
                            <input type="hidden" name="iddata" value={this.state.id} />
                            <input type="hidden" name="token" value={getCookie("Token")} />
                            <input type="hidden" name="detail" value={JSON.stringify(this.state.detail)} />
                            <div className="form-group mb-3">
                                <label>Judul</label>
                                <input type="text" name="NameSubArticle" className="form-control" placeholder='masukan Judul' value={this.state.NameSubArticle} onChange={(e) => this.handleChange(e, "NameSubArticle")} required />
                                <div className="invalid-feedback">Silahkan masukan Judul</div>
                            </div>
                            <p></p>
                            <button className='btn btn-primary' type='button' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => this.handleCariArticle("")}>Tambah Artikel</button>
                            <p></p>
                            <div className='table-responsive'>
                                <table className='table table-striped'>
                                    <thead>
                                        <tr>
                                            <th>Artikel</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.detail.map((item, ii) => {
                                                return (
                                                    <tr key={ii}>
                                                        <td>{item.NameArticle}</td>
                                                        <td>
                                                            <span className='material-icons cursor' onClick={(e) => this.handleDeleteArticle(ii)}>delete</span>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <p></p>
                            <button type="submit" id='btnSave' className='btn  btn-default'><span></span> Simpan</button>
                        </div>
                    </form>

                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog shadow">
                            <div className="modal-content" style={{ zIndex: "999999999" }}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Data Artikel</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <input className='form-control' onChange={(e) => this.handleCariArticle(e.target.value)} placeholder='Cari Nama Artikel' />
                                    <p></p>
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>Nama Artikel</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.dataArticle.map((item, i) => {
                                                        return (
                                                            <tr key={i} onClick={() => this.handlePilihArticle(item.ID, item.NameArticle)}>
                                                                <td>{item.NameArticle}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id='btnTutupModal' data-bs-dismiss="modal">Close</button>
                                    <button type="button" className="btn btn-primary">Save changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    class FormArticle extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                id: this.props.ID,
                NameArticle: "",
                Title: "Tambah Kategori",
                Url: "",
                changeImg: "no"
            }
        }

        async componentDidMount() {
            ReactDOM.render(<div className='btn-kembali' onClick={() => App("article")}><spam className="material-icons">arrow_back</spam> Kembali</div>, document.getElementById('divHeaderLabel'));
            if (this.state.id !== "") {
                let sql = await api("article_detail", { iddata: this.state.id, token: getCookie("Token") });
                this.setState({
                    NameArticle: sql.data.NameArticle,
                    Title: "Edit Kategori",
                    Url: sql.data.Url
                });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        handleSubmitForm(e) {
            e.preventDefault();
            e.stopPropagation();
            let btn = document.getElementById('btnSave');;
            let oldBtn = btn.innerHTML.trim();
            if (e.target.checkValidity()) {
                btn.innerHTML = `<span class="spinner-border spinner-border-sm"></span> ${oldBtn}`;
                btn.disabled = true;
                let data = new FormData(e.target);
                let host = window.location.hostname === "localhost" ? "http://localhost:2005/api_mobile/" : "https://api.srisannano.com/api_mobile/";
                let Url = `${host}${this.state.id ? "article_edit" : "article_insert"}`;
                fetch(Url, {
                    method: 'POST',
                    body: data,
                }).then(response => response.json()).then(hasil => {
                    if (hasil.status === "true") {
                        pesan("Proses Success", hasil.message, "primary");
                        App("article");
                    } else {
                        alert(hasil.message);
                        btn.innerHTML = oldBtn;
                        btn.disabled = false;
                    }
                }).catch((error) => {
                    console.log("Error: " + error);
                    btn.innerHTML = oldBtn;
                    btn.disabled = false;
                });
            } else {
                let forms = document.getElementsByClassName('needs-validation');
                let ululu = Array.prototype.filter.call(forms, function (form) {
                    form.classList.add('was-validated');
                });
            }
        }

        render() {
            let act = this.state.id ? "article_edit" : "article_insert";
            let divLink = [];
            if (this.state.id != "") {
                let host = window.location.hostname === "localhost" ? "http://localhost:2005/" : "https://api.srisannano.com/";
                divLink = <a href={`${host}file/${this.state.Url}`} target="_blank">Link Pdf</a>
            }
            return (
                <Fragment>
                    <form id='iniForm' onSubmit={(e) => this.handleSubmitForm(e)} className="needs-validation" noValidate>
                        <div className='card-form'>
                            <h3 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>{this.state.Title}</h3>
                            <input type="hidden" name="iddata" value={this.state.id} />
                            <input type="hidden" name="token" value={getCookie("Token")} />
                            <input type="hidden" name="changeImg" value={this.state.changeImg} />
                            <div className="form-group mb-3">
                                <label>Judul</label>
                                <input type="text" name="NameArticle" className="form-control" placeholder='masukan Judul' value={this.state.NameArticle} onChange={(e) => this.handleChange(e, "NameArticle")} required />
                                <div className="invalid-feedback">Silahkan masukan Judul</div>
                            </div>
                            {
                                divLink
                            }
                            <div className='form-group'>
                                <label>File PDF</label>
                                <input type="file" name="Img" className='form-control' accept="application/pdf" required={this.state.id == "" ? true : false} onChange={() => this.setState({ changeImg: "yes" })} />
                                <div className='invalid-feedback'>Silahkan pilih file</div>
                            </div>
                            <p></p>
                            <button type="submit" id='btnSave' className='btn  btn-default'><span></span> Simpan</button>
                        </div>
                    </form>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

export default App;