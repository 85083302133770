import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { saiki, api, loading, rendModal, rendTable, getCookie, submitForm } from "../modul";
import img_upload from '../bg_uppload-image.png';

function App() {
    document.title = "Organisasi";
    var typingTimer;

    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NamaCampaign",
                by: "DESC",
                idlocation: 0
            };
        }

        componentDidMount() {
            ReactDOM.render("", document.getElementById('divHeaderLabel'));
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            clearTimeout(typingTimer);
            typingTimer = setTimeout(async () => {
                this.main();
            }, 500);
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => { this.main(); }, 100);
        }

        modalDelete(ini) {
            let dom = (
                <Fragment>
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <input type="hidden" name="token" value={getCookie("Token")} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </Fragment>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "campaign_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        async main() {
            let param = {
                search: this.state.search,
                index: this.state.index,
                searchcount: this.state.searchcount,
                token: getCookie("Token"),
                sort: this.state.sort,
                by: this.state.by
            }

            let sql = await api("campaign_browse", param);

            let field = {
                thead: [
                    { cap: "", sort: "", type: "opsi", width: "100px" },
                    { cap: "Logo", sort: "Url", type: "img", width: "120px", align: "center" },
                    { cap: "Nama Organisasi", sort: "NamaCampaign", type: "str", width: "150px" },
                    { cap: "Nama Penanggung Jawab", sort: "PenanggungJawab", type: "str", width: "200px" },
                    { cap: "Nomor Telp", sort: "NomorTelp", type: "str", width: "150px" },
                    { cap: "Wilayah", sort: "NamaLokasi", type: "str", width: "150px" },
                    { cap: "Deskripsi", sort: "Description", type: "html", width: "250px" },
                    { cap: "Alamat", sort: "Alamat", type: "str" }
                ],
                j: sql.ct
            }

            rendTable({
                dataset: { data: sql.data, field: field },
                div: "divView",
                table: { cls: "table  " },
                thead: { cls: "thead-dark" },
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                tbody: {
                    id: "tbodyDataBooking",
                    opsi: [
                        { type: "edit", event: (e) => rendForm(e) },
                        { type: "delete", event: (e) => this.modalDelete(e) },
                    ]
                },
                paggination: true
            });
        }

        render() {
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Organisasi</h2>
                        <div className='row'>
                            <div className='col-md-9'>
                                <button className="btn btn-default" onClick={(e) => rendForm(e)}>+ Tambah</button>
                            </div>
                            <div className='col-md-3'>
                                <div className='input-icons'>
                                    <span className='material-icons icon-search'>search</span>
                                    <input className="form-control search-form" type="search" name="search" placeholder="Search" value={this.state.search} onChange={(e) => this.handleChange(e, "search")} required />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div id="divView" style={{ overflowX: "scroll", width: "80vw" }}></div>
                    </div>
                    <nav id="paggination" style={{ paddingRight: "50px" }}></nav>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                KodeCampaign: "",
                NamaCampaign: "",
                PenanggungJawab: "",
                NomorTelp: "",
                Alamat: "",
                Url: "",
                Rekening: "",
                changeImg: "no",
                isReqImg: true,
                Title: "Tambah Organisasi",
                Description: "",
                IDLokasi: "",
                Lokasi: []
            }
        }

        async componentDidMount() {
            ReactDOM.render(<div className='btn-kembali' onClick={() => App()}><spam className="material-icons">arrow_back</spam> Kembali</div>, document.getElementById('divHeaderLabel'));
            let sql = await api("campaign_detail", { iddata: this.state.id, token: getCookie("Token") });
            if (this.state.id !== "") {
                this.setState({
                    KodeCampaign: sql.data.KodeCampaign,
                    NamaCampaign: sql.data.NamaCampaign,
                    PenanggungJawab: sql.data.PenanggungJawab,
                    NomorTelp: sql.data.NomorTelp,
                    Alamat: sql.data.Alamat,
                    Url: sql.data.Url,
                    IDLokasi: sql.data.IDLokasi,
                    Rekening: sql.data.Rekening,
                    isReqImg: false,
                    Title: "Edit Organisasi",
                    Description: sql.data.Description ? sql.data.Description.replaceAll("<br>", '\n') : "",
                    Lokasi: sql.Lokasi
                });
            } else {
                this.setState({ Lokasi: sql.Lokasi })
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        handleCheck(e) {
            this.setState({ IsApproval: e.checked });
        }

        openFile(e) {
            document.getElementById("labelImg").innerHTML = '';
            let files = e.target.files;
            let file;
            for (let i = 0; i < files.length; i++) {
                let reader = new FileReader();
                file = files[i];
                reader.onload = (file) => {
                    document.getElementById("labelImg").innerHTML += `<img src="${reader.result}" width="256px" height="159px">`;
                    this.setState({ changeImg: "yes" });
                }
                reader.readAsDataURL(file)
            }
        }

        render() {
            let divImg = <img src={img_upload} width="256px" height="159px" />;
            if (this.state.Url != "") {
                let host = window.location.hostname === "localhost" ? "http://localhost:2005/" : "https://api.srisannano.com/";
                divImg = <img src={host + "file/" + this.state.Url} width="256px" height="159px" />
            }
            let act = this.state.id != "" ? "campaign_edit" : "campaign_insert"
            return (
                <Fragment>
                    <form id='iniForm' onSubmit={(e) => submitForm(e, { act: act, reload: true })} className="needs-validation" noValidate>
                        <div className='card-form'>
                            <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>{this.state.Title}</h2>
                            <input type="hidden" name="iddata" value={this.state.id} />
                            <input type="hidden" name="token" value={getCookie("Token")} />
                            <input type="hidden" name="changeImg" value={this.state.changeImg} />
                            <div className="form-group mb-3">
                                <label>Kode</label>
                                <input type="text" name="KodeCampaign" className="form-control" placeholder='Masukan Kode Campaign' value={this.state.KodeCampaign} onChange={(e) => this.handleChange(e, "KodeCampaign")} required />
                                <div className="invalid-feedback">Silahkan masukan Kode campaign</div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Nama</label>
                                <input type="text" name="NamaCampaign" className="form-control" placeholder='masukan nama campaign' value={this.state.NamaCampaign} onChange={(e) => this.handleChange(e, "NamaCampaign")} required />
                                <div className="invalid-feedback">Silahkan masukan nama campaign</div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Nama Penanggung Jawab</label>
                                <input type="text" name="PenanggungJawab" className="form-control" placeholder='masukan penanggung jawab campaign' id="edtName" value={this.state.PenanggungJawab} onChange={(e) => this.handleChange(e, "PenanggungJawab")} required />
                                <div className="invalid-feedback">Silahkan masukan nama penanggung jawab</div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Nomor Telp</label>
                                <input type="tel" name="NomorTelp" pattern="[0-9]*" className="form-control" placeholder='masukan nomor telp' id="edtTelp" value={this.state.NomorTelp} onChange={(e) => this.handleChange(e, "NomorTelp")} required />
                                <div className="invalid-feedback">Silahkan masukan nomor telp</div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Rekening</label>
                                <input type="text" name="Rekening" className="form-control" placeholder='Rekening' value={this.state.Rekening} onChange={(e) => this.handleChange(e, "Rekening")} />
                                <div className="invalid-feedback">Silahkan masukan rekening</div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Wilayah</label>
                                <select name="IDLokasi" className="form-select" value={this.state.IDLokasi} onChange={(e) => this.handleChange(e, "IDLokasi")} >
                                    {this.state.Lokasi.map((item, i) => {
                                        return <option value={item.ID} key={i}>{item.NamaLokasi}</option>
                                    })}
                                </select>
                                <div className="invalid-feedback">Silahkan masukan nomor telp</div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Alamat</label>
                                <input type="text" name="Alamat" className="form-control" placeholder='masukan alamat' id="edtAlamat" value={this.state.Alamat} onChange={(e) => this.handleChange(e, "Alamat")} required />
                                <div className="invalid-feedback">Silahkan masukan alamat</div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Keterangan</label>
                                <textarea type="text" name="Description" className="form-control" placeholder='masukan Katerangan' value={this.state.Description} onChange={(e) => this.handleChange(e, "Description")} />
                                <div className="invalid-feedback">Silahkan masukan alamat</div>
                            </div>
                            <div className='form-group'>
                                <label>Gambar</label>
                                <div className='image-upload'>
                                    <label id="labelImg" for="image-upload">
                                        {divImg}
                                    </label>
                                    <input type="file" accept='image/*' name="Img" id="image-upload" onChange={(e) => this.openFile(e)} required={this.state.isReqImg} />
                                    <div className="invalid-feedback">Silahkan pilih Gambar</div>
                                </div>
                            </div>
                            <p></p>
                            <button type="submit" className='btn  btn-default'><span></span> Simpan</button>
                        </div>
                    </form>
                </Fragment>
            )
        }
    }

    let id = ini.target.dataset.id, act = id !== undefined ? "employee_edit" : "employee_insert";

    ReactDOM.render(<Form />, document.getElementById('tampil'));
}
export default App;