import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { saiki, api, loading, rendModal, rendTable, getCookie, submitForm } from "../modul";
import img_upload from '../bg_uppload-image.png';

function App() {
    document.title = "Master Banner";
    var typingTimer;
    var delay = 500;

    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NamaBanner",
                by: "ASC",
                idlocation: 0
            };
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            clearTimeout(typingTimer);
            typingTimer = setTimeout(async () => {
                this.main();
            }, delay);
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => { this.main(); }, 100);
        }

        modalDelete(ini) {
            let dom = (
                <Fragment>
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <input type="hidden" name="token" value={getCookie("Token")} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </Fragment>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "banner_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        async main() {
            let param = {
                search: this.state.search,
                index: this.state.index,
                searchcount: this.state.searchcount,
                token: getCookie("Token"),
                sort: this.state.sort,
                by: this.state.by
            }
            let sql = await api("banner_browse", param);
            console.log(sql);
            let field = {
                thead: [
                    { cap: "", sort: "", type: "opsi", width: "100px" },
                    { cap: "Gambar", sort: "Gambar", type: "img", width: "60px" },
                    { cap: "Nama Banner", sort: "NamaBanner", width: "150px" },
                    { cap: "Tanggal", sort: "TanggalBuat" }
                ],
                j: sql.ct
            }
            rendTable({
                dataset: { data: sql.data, field: field },
                div: "divView",
                table: { cls: "table  " },
                thead: { cls: "thead-dark" },
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                tbody: {
                    id: "tbodyDataBanner",
                    opsi: [
                        { type: "edit", event: (e) => rendForm(e) },
                        { type: "delete", event: (e) => this.modalDelete(e) },
                    ]
                },
                paggination: true
            });
        }

        async componentDidMount() {
            ReactDOM.render("", document.getElementById('divHeaderLabel'));
            this.main();
            loading();
        }

        render() {
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Banner</h2>
                        <div className='row'>
                            <div className='col-md-9'>
                                <button className="btn btn-default" onClick={(e) => rendForm(e)}>+ Tambah</button>
                            </div>
                            <div className='col-md-3'>
                                <div className='input-icons'>
                                    <span className='material-icons icon-search'>search</span>
                                    <input className="form-control search-form" type="search" name="search" placeholder="Search" value={this.state.search} onChange={(e) => this.handleChange(e, "search")} required />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView"></div>
                    </div>
                    <nav id="paggination" style={{ paddingRight: "50px" }}></nav>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                isChange: false,
                isReqImg: true,
                NamaBanner: "",
                Gambar: "",
                isChange: "no",
                Title: 'Tambah Banner',
            }
        }

        async componentDidMount() {
            ReactDOM.render(<div className='btn-kembali' onClick={() => App()}><spam className="material-icons">arrow_back</spam> Kembali</div>, document.getElementById('divHeaderLabel'));
            if (this.state.id !== "") {
                let sql = await api("banner_detail", { iddata: this.state.id, token: getCookie("Token") });
                this.setState({
                    NamaBanner: sql.data.NamaBanner,
                    isReqImg: false,
                    Gambar: sql.data.Gambar,
                    isChange: "no",
                    Title: 'Edit Banner',
                });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        openFile(e) {
            document.getElementById("labelImg").innerHTML = '';
            let files = e.target.files;
            let file;
            for (let i = 0; i < files.length; i++) {
                let reader = new FileReader();
                file = files[i];
                reader.onload = (file) => {
                    document.getElementById("labelImg").innerHTML += `<img src="${reader.result}" width="256px" height="159px">`;
                    this.setState({ isChange: "yes" });
                }
                reader.readAsDataURL(file)
            }
        }

        render() {
            let divImg = (
                <img src={img_upload} width="256px" height="159px" />
            );
            if (this.state.Gambar != "") {
                let host = window.location.hostname === "localhost" ? "http://localhost:2005/" : "https://api.srisannano.com/";
                divImg = <img src={host + "file/" + this.state.Gambar} width="256px" height="159px" />
            }
            let act = this.state.id ? "banner_edit" : "banner_insert";
            return (
                <Fragment>
                    <form id='iniForm' onSubmit={(e) => submitForm(e, { act: act, reload: true })} className="needs-validation" noValidate>
                        <div className='card-form'>
                            <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>{this.state.Title}</h2>
                            <input type="hidden" name="iddata" value={this.state.id} />
                            <input type="hidden" name="token" value={getCookie("Token")} />
                            <input type="hidden" name="isChange" value={this.state.isChange} />
                            <div className="form-group mb-3">
                                <label>Nama Banner</label>
                                <input type="text" name="NamaBanner" className="form-control" placeholder='masukan nama Banner' value={this.state.NamaBanner} onChange={(e) => this.handleChange(e, "NamaBanner")} required />
                                <div className="invalid-feedback">Silahkan masukan nama Banner</div>
                            </div>
                            <div className="form-group">
                                <label>Upload Banner</label>
                                <div className='image-upload'>
                                    <label id="labelImg" for="image-upload">
                                        {divImg}
                                    </label>
                                    <input type="file" accept='image/*' name="Img" id="image-upload" onChange={(e) => this.openFile(e)} required={this.state.isReqImg} />
                                    <div className="invalid-feedback">Silahkan pilih Gambar</div>
                                </div>
                            </div>
                            <p></p>
                            <button type="submit" className='btn btn-default'><span></span> Simpan</button>
                        </div>
                    </form>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Form />, document.getElementById('tampil'));
}
export default App;