import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { api, loading, rendModal, rendTable, getCookie, submitForm, pesan } from "../modul";
import img_upload from '../bg_uppload-image.png';

function App(mode = "gambar", IDKategori = 1, FormName = "Explore") {
    document.title = FormName;
    var typingTimer;
    var delay = 500;

    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "ID",
                by: "DESC",
                idlocation: 0,
                mode: mode
            };
        }

        componentDidMount() {
            ReactDOM.render("", document.getElementById('divHeaderLabel'));
            if (mode != "gambar") document.getElementById('pills-profile-tab').click();
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            clearTimeout(typingTimer);
            typingTimer = setTimeout(async () => {
                this.main();
            }, delay);
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => { this.main(); }, 100);
        }

        modalDelete(ini) {
            rendModal({
                id: "modalDelete",
                body: <Fragment><h5>Apakah Anda yakin akan menghapus data ini</h5></Fragment>,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });

            let h = this;

            document.getElementById('iniForm').addEventListener("submit", async function (e) {
                e.preventDefault();
                e.stopPropagation();
                let sql = await api("video_delete", { iddata: ini.target.dataset.id, token: getCookie("Token") });
                if (sql.status == "true") {
                    h.main();
                    document.getElementById('btnCloseModalmodalDelete').click();
                } else {
                    pesan("Peringatan", sql.message, "danger");
                }
            });
        }

        handleMode(mode) {
            this.setState({ mode: mode });
            setTimeout(() => {
                this.main();
            }, 200);
        }

        async main() {
            let param = {
                search: this.state.search,
                index: this.state.index,
                searchcount: this.state.searchcount,
                token: getCookie("Token"),
                sort: this.state.sort,
                by: this.state.by,
                mode: this.state.mode,
                type: "Explore",
                IDKategori: IDKategori
            }

            let sql = await api("explore_browse", param);

            rendTable({
                dataset: sql,
                div: "divView",
                table: { cls: "table  " },
                thead: { cls: "thead-dark" },
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                tbody: {
                    id: "tbodyDataVideo",
                    opsi: [
                        { type: "edit", event: (e) => rendForm(e, this.state.mode, IDKategori, FormName) },
                        { type: "delete", event: (e) => this.modalDelete(e) },
                    ],
                    link: (e) => this.handleLink(e)
                },
                paggination: true
            });
        }

        render() {
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>{FormName}</h2>
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link nav-link-kuning active" onClick={(e) => this.handleMode("gambar")} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Upload Gambar</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link nav-link-kuning" onClick={(e) => this.handleMode("video")} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Upload Youtube</button>
                            </li>
                        </ul>
                        <div className='row'>
                            <div className='col-md-9'>
                                <button className="btn btn-default" onClick={(e) => rendForm(e, this.state.mode, IDKategori, FormName)}>+ Tambah</button>
                            </div>
                            <div className='col-md-3'>
                                <div className='input-icons'>
                                    <span className='material-icons icon-search'>search</span>
                                    <input className="form-control search-form" type="search" name="search" placeholder="Search" value={this.state.search} onChange={(e) => this.handleChange(e, "search")} required />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView"></div>
                        <nav id="paggination" style={{ paddingRight: "50px" }}></nav>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

function rendForm(ini, mode = 'gambar', IDKategori = 1, FormName) {
    class Form extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                Judul: "",
                IDKategori: IDKategori,
                Kategori: [],
                Url: "",
                Gambar: "",
                Isi: "",
                Title: "Tambah Top Trending",
                changeImg: "no",
                isReqImg: mode == "gambar" ? true : false,
                isReqUrl: mode == "gambar" ? false : true,
                divUrl: mode == "gambar" ? "none" : "block",
                divImg: mode == "gambar" ? "block" : "none",
            }
        }

        async componentDidMount() {
            ReactDOM.render(<div className='btn-kembali' onClick={() => App()}><spam className="material-icons">arrow_back</spam> Kembali</div>, document.getElementById('divHeaderLabel'));
            let sql = await api("video_detail", { iddata: this.state.id, token: getCookie("Token") });
            this.setState({ Kategori: sql.kategori });
            if (sql.data) {
                this.setState({
                    Judul: sql.data.Judul,
                    Url: sql.data.Url,
                    Title: "Edit Top Trending",
                    Gambar: sql.data.Gambar,
                    Isi: sql.data.Isi,
                    IDKategori: sql.data.IDKategori,
                    isReqImg: false,
                    changeImg: "no"
                });
            }
        }

        openFile(e) {
            document.getElementById("labelImg").innerHTML = '';
            let files = e.target.files;
            let file;
            for (let i = 0; i < files.length; i++) {
                let reader = new FileReader();
                file = files[i];
                reader.onload = (file) => {
                    document.getElementById("labelImg").innerHTML += `<img src="${reader.result}" width="256px" height="159px">`;
                    this.setState({ changeImg: "yes" });
                }
                reader.readAsDataURL(file)
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        handleSubmit(e) {
            e.preventDefault();
            e.stopPropagation();
            let btn = document.getElementById('btnSubmit');;
            let oldBtn = btn.innerHTML.trim();
            if (e.target.checkValidity()) {
                btn.innerHTML = `<span class="spinner-border spinner-border-sm"></span> ${oldBtn}`;
                btn.disabled = true;
                let data = new FormData(e.target);
                let host = window.location.hostname === "localhost" ? "http://localhost:2005/api_mobile/" : "https://api.srisannano.com/api_mobile/";
                let Url = `${host}${this.state.id ? "explore_edit" : "explore_insert"}`;
                fetch(Url, {
                    method: 'POST',
                    body: data,
                }).then(response => response.json()).then(hasil => {
                    if (hasil.status === "true") {
                        pesan("Proses Success", hasil.message, "primary");
                        App(mode, IDKategori, FormName);
                    } else {
                        alert(hasil.message);
                        btn.innerHTML = oldBtn;
                        btn.disabled = false;
                    }
                }).catch((error) => {
                    console.log("Error: " + error);
                    btn.innerHTML = oldBtn;
                    btn.disabled = false;
                });
            } else {
                let forms = document.getElementsByClassName('needs-validation');
                let ululu = Array.prototype.filter.call(forms, function (form) {
                    form.classList.add('was-validated');
                });
            }
        }

        render() {
            let divImg = (
                <img src={img_upload} width="256px" height="159px" />
            );
            if (this.state.Gambar != "") {
                let host = window.location.hostname === "localhost" ? "http://localhost:2005/" : "https://api.srisannano.com/";
                divImg = <img src={host + "file/" + this.state.Gambar} width="256px" height="159px" />
            }
            return (
                <Fragment>
                    <form id='iniForm' onSubmit={(e) => this.handleSubmit(e)} className="needs-validation" noValidate>
                        <div className='card-form'>
                            <h3 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>{this.state.Title}</h3>
                            <input type="hidden" name="iddata" value={this.state.id} />
                            <input type="hidden" name="token" value={getCookie("Token")} />
                            <input type="hidden" name="TipeData" value="Explore" />
                            <input type="hidden" name="mode" value={mode} />
                            <input type="hidden" name="IDKategori" value={IDKategori} />
                            <input type="hidden" name="changeImg" value={this.state.changeImg} />
                            <div className="form-group mb-3">
                                <label>Judul</label>
                                <input type="text" name="Judul" className="form-control" placeholder='Masukan Judul' value={this.state.Judul} onChange={(e) => this.handleChange(e, "Judul")} required />
                                <div className="invalid-feedback">Silahkan masukan Judul</div>
                            </div>
                            {/* <div className="form-group mb-3">
                                <label>Kategori</label>
                                <select className='form-select' name='IDKategori' value={this.state.IDKategori} onChange={(e) => this.setState({ IDKategori: e.target.value })} required={true}>
                                    {
                                        this.state.Kategori.map((item, i) => {
                                            return (
                                                <option value={item.ID}>{item.NamaKategori}</option>
                                            )
                                        })
                                    }
                                </select>
                                <div className="invalid-feedback">Silahkan Pilih kategori</div>
                            </div> */}
                            <div className="form-group mb-3" style={{ display: this.state.divUrl }}>
                                <label>Link Youtube</label>
                                <input type="text" name="Url" className="form-control" placeholder='masukan link youtube' value={this.state.Url} onChange={(e) => this.handleChange(e, "Url")} required={this.state.isReqUrl} />
                                <div className="invalid-feedback">Silahkan masukan Link Youtube</div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Deskripsi</label>
                                <textarea name="Isi" className="form-control" placeholder='tuliskan deskripsi' value={this.state.Isi} onChange={(e) => this.handleChange(e, "Isi")} required />
                                <div className="invalid-feedback">Silahkan tuliskan deskripsi</div>
                            </div>
                            <div className='image-upload' style={{ display: this.state.divImg }}>
                                <label id="labelImg" for="image-upload">
                                    {divImg}
                                </label>
                                <input type="file" accept='image/*' name="Img" id="image-upload" onChange={(e) => this.openFile(e)} required={this.state.isReqImg} />
                                <div className="invalid-feedback">Silahkan pilih Gambar</div>
                            </div>
                            <p></p>
                            <button type="submit" className='btn btn-default' id='btnSubmit'><span></span> Simpan</button>
                        </div>
                    </form>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Form />, document.getElementById('tampil'));
}
export default App;