import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { saiki, api, loading, rendModal, rendTable, getCookie, submitForm, tanggalIndo, numberFormat } from "../modul";

function App() {
    document.title = "Donasi";
    var typingTimer;
    var delay = 500;

    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "TanggalBuat",
                by: "DESC",
                berlangsung: 0,
                terkumpul: 0,
                berahir: 0,
                totalSelesai: 0,
                arrOrganisasi: [],
                arrKategori: [],
                Status: "All",
                Campaign: "All",
                Kategori: "All"
            };
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            clearTimeout(typingTimer);
            typingTimer = setTimeout(async () => {
                this.main();
            }, delay);
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleRendForm(ini = "") {
            if (ini != "") {
                rendForm(ini.target.dataset.id);
            } else {
                rendForm(ini);
            }
        }

        handleStatus(ssts) {
            this.setState({ Status: ssts });
            setTimeout(() => {
                this.main();
            }, 200);
        }

        async main() {
            let param = {
                search: this.state.search,
                index: this.state.index,
                searchcount: this.state.searchcount,
                token: getCookie("Token"),
                sort: this.state.sort,
                by: this.state.by,
                Campaign: this.state.Campaign,
                Kategori: this.state.Kategori,
                Status: this.state.Status
            }
            let sql = await api("donasi_browse", param);
            this.setState({ berahir: sql.berahir, berlangsung: sql.berlangsung, terkumpul: sql.terkumpul });
            let field = {
                thead: [
                    { cap: "", sort: "", type: "opsi", width: "100px" },
                    { cap: "Judul Event", sort: "NamaEvent", width: "250px" },
                    { cap: "Jumlah Nominal", sort: "TargetDonasi", width: "150px", align: "right" },
                    { cap: "Jumlah Terkumpul", sort: "JumlahTerkumpul", width: "150px", align: "right" },
                    { cap: "Nominal Terpakai", sort: "NominalTerpakai", width: "150px", align: "right" },
                    { cap: "Batas Hari", sort: "BatasHari", width: "150px" },
                    { cap: "Status", sort: "State", width: "100px", type: "html" },
                    { cap: "", sort: "SisaHari", type: "html" }
                ],
                j: sql.ct
            }
            rendTable({
                dataset: { data: sql.data, field: field },
                div: "divView",
                table: { cls: "table  " },
                thead: { cls: "thead-dark" },
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                tbody: {
                    id: "tbodyDataBooking",
                    opsi: [
                        { type: "other", icon: "more_horiz", event: (e) => this.handleRendForm(e) },
                    ]
                },
                paggination: true
            });
        }

        async componentDidMount() {
            ReactDOM.render("", document.getElementById('divHeaderLabel'));
            let sqlOrganisasi = await api("campaign_browse", { token: getCookie("Token") });
            let sqlKategori = await api("kategori_browse", { token: getCookie("Token") });
            this.setState({ arrOrganisasi: sqlOrganisasi.data, arrKategori: sqlKategori.data });
            this.main();
            loading();
        }

        render() {
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Donasi</h2>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card card-donasi-biru shadow-tipis" style={{ border: "none" }} onClick={(e) => this.handleStatus('Berlangsung')}>
                                    <div className="card-body" style={{ minHeight: "80px", textAlign: "left", cursor: "pointer" }}>
                                        <span className='material-icons' style={{ color: "#57EAE1", backgroundColor: "#DCFFFD", padding: "8px", borderRadius: "8px" }}>calendar_month</span>
                                        <br></br>
                                        <span style={{ fontSize: "16px" }}>Donasi Berlangsung</span>
                                        <h1 style={{ fontSize: "32px" }}>{this.state.berlangsung} Event</h1>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="card card-donasi-kuning shadow-tipis" style={{ border: "none" }} onClick={(e) => this.handleStatus('Terkumpul')}>
                                    <div className="card-body" style={{ minHeight: "80px", textAlign: "left", cursor: "pointer" }}>
                                        <span className='material-icons' style={{ color: "#FFE46B", backgroundColor: "#FFF7D3", padding: "8px", borderRadius: "8px" }}>calendar_month</span>
                                        <br></br>
                                        <span style={{ fontSize: "16px" }}>Donasi Terkumpul</span>
                                        <h1 style={{ fontSize: "32px" }}>{numberFormat(this.state.terkumpul)} Event</h1>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="card card-donasi-hijau shadow-tipis" style={{ border: "none" }} onClick={(e) => this.handleStatus('Berahir')}>
                                    <div className="card-body" style={{ minHeight: "80px", textAlign: "left" }}>
                                        <span className='material-icons' style={{ color: "#2ECC71", backgroundColor: "#C0F0D4", padding: "8px", borderRadius: "8px" }}>calendar_month</span>
                                        <br></br>
                                        <span style={{ fontSize: "16px" }}>Campaign Selesai</span>
                                        <h1 style={{ fontSize: "32px" }}>{this.state.berahir} Event</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className='row'>
                            <div className='col-md-3 mb-1'>
                                <div className='form-group'>
                                    <label style={{ fontSize: "14px" }}>Penggalang Dana</label>
                                    <select className='form-select' onChange={(e) => this.handleChange(e, "Campaign")}>
                                        <option value="All">Semua Penggalang Dana</option>
                                        {
                                            this.state.arrOrganisasi.map((val, i) => {
                                                return (
                                                    <option value={val.ID} key={i}>{val.NamaCampaign}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='col-md-3 mb-1'>
                                <div className='form-group'>
                                    <label style={{ fontSize: "14px" }}>Kategori Donasi</label>
                                    <select className='form-select' onChange={(e) => this.handleChange(e, "Kategori")}>
                                        <option value="All">Semua Kategori</option>
                                        {
                                            this.state.arrKategori.map((val, i) => {
                                                return (
                                                    <option value={val.ID} key={i}>{val.NamaKategori}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView"></div>
                        <nav id="paggination" style={{ paddingRight: "50px" }}></nav>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

function rendForm(id, mode = "penarikan") {
    class FormIni extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                id: id,
                mode: mode,
                indexPenarikan: 1,
                sortPenarikan: "TanggalBuat",
                byPenarikan: "DESC",
                searchPenarikan: "",
                indexDonatur: 1,
                sortDonatur: "Nama",
                byDonatur: "ASC",
                searchDonatur: "",
                NamaEvent: "",
                TargetDonasi: 0,
                JumlahTekumpul: 0,
                NominalTerpakai: 0,
                BatasHari: 0,
                IDEvent: "",
                title: '',
                Gambar: "",
                NamaKategori: "",
                total: 0,
                terkumpul: 0,
                target: 0
            }
        }

        async componentDidMount() {
            ReactDOM.render(<div className='btn-kembali' onClick={() => App()}><spam className="material-icons">arrow_back</spam> Kembali</div>, document.getElementById('divHeaderLabel'));
            let sql = await api("donasi_detail", { token: getCookie("Token"), ID: id });
            this.setState({
                title: `Update Berita Penarikan Dana`,
                IDEvent: sql.data.IDEvent,
                NamaEvent: sql.data.NamaEvent,
                NamaKategori: sql.data.NamaKategori,
                TargetDonasi: sql.data.TargetDonasi,
                JumlahTekumpul: sql.data.JumlahTekumpul,
                NominalTerpakai: sql.data.NominalTerpakai,
                BatasHari: sql.data.BatasHari,
                Gambar: sql.data.Url
            });
            setTimeout(() => {
                if (mode == "penarikan") {
                    this.mainPenarikan();
                } else {
                    this.mainDonatur();
                }
            }, 200);
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        handlePagePenarikan(e) {
            e.preventDefault();
            this.setState({ indexPenarikan: e.target.dataset.val });
            setTimeout(() => {
                this.mainPenarikan();
            }, 100);
        }

        handleSortPenarikan(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ byPenarikan: "DESC" });
                } else {
                    this.setState({ byPenarikan: "ASC" });
                }
            } else {
                this.setState({ sortPenarikan: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.mainPenarikan();
            }, 100);
        }

        handlePageDonatur(e) {
            e.preventDefault();
            this.setState({ indexDonatur: e.target.dataset.val });
            setTimeout(() => {
                this.mainDonatur();
            }, 100);
        }

        handleSortDonatur(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ byDonatur: "DESC" });
                } else {
                    this.setState({ byDonatur: "ASC" });
                }
            } else {
                this.setState({ sortDonatur: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.mainDonatur();
            }, 100);
        }

        modalDelete(ini) {
            let dom = (
                <Fragment>
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <input type="hidden" name="token" value={getCookie("Token")} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </Fragment>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "penarikan_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        async mainPenarikan() {
            let param = {
                search: this.state.searchPenarikan,
                index: this.state.indexPenarikan,
                searchcount: 100,
                token: getCookie("Token"),
                sort: this.state.sortPenarikan,
                by: this.state.byPenarikan,
                EventID: this.state.id
            }
            let sql = await api("penarikan_browse", param);
            let field = {
                thead: [
                    { cap: "", sort: "", type: "opsi", width: "150px" },
                    { cap: "Tanggal", sort: "TanggalBuat", width: "100px" },
                    { cap: "Nominal Penarikan", sort: "Nominal", width: "150px" },
                    { cap: "Deskripsi", sort: "Berita", type: "html" }
                ],
                j: sql.ct
            }
            rendTable({
                dataset: { data: sql.data, field: field },
                div: "divViewPenarikan",
                table: { cls: "table  " },
                thead: { cls: "thead-dark" },
                sort: (e) => this.handleSortPenarikan(e),
                paggination: { event: (e) => this.handlePagePenarikan(e), index: this.state.index },
                tbody: {
                    id: "tbodyDataBooking",
                    opsi: [
                        { type: "edit", event: (e) => rendFormPenarikan(e, this.state.id) },
                        { type: "delete", event: (e) => this.modalDelete(e) },
                    ]
                },
                paggination: { div: "pgPenarikan" }
            });
        }

        async mainDonatur() {
            let param = {
                search: this.state.searchDonatur,
                index: this.state.indexDonatur,
                searchcount: 100,
                token: getCookie("Token"),
                sort: this.state.sortDonatur,
                by: this.state.byDonatur,
                IDEvent: id
            }
            let sql = await api("donatur_donasi_browse", param);
            this.setState({ total: sql.total, terkumpul: sql.terkumpul, target: sql.target });
            console.log(sql);
            let field = {
                thead: [
                    { cap: "Foto", sort: "Gambar" },
                    { cap: "Nama", sort: "Nama" },
                    { cap: "Nominal Donasi", sort: "Nominal" }
                ],
                j: sql.ct
            }
            rendTable({
                dataset: { data: sql.data, field: field },
                div: "divViewDonatur",
                table: { cls: "table  " },
                thead: { cls: "thead-dark" },
                sort: (e) => this.handleSortDonatur(e),
                paggination: { event: (e) => this.handlePageDonatur(e), index: this.state.index },
                tbody: { id: "tbodyDataDonatur" },
                paggination: { div: "pgDonatur" }
            });
        }

        render() {
            let host = window.location.hostname === "localhost" ? "http://localhost:2005/file/" : "https://api.srisannano.com/file/";
            return (
                <Fragment>
                    <div className='card-form'>
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>{this.state.title}</h2>
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link nav-link-kuning active" onClick={(e) => this.mainPenarikan(e)} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Penarikan</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link nav-link-kuning" onClick={(e) => this.mainDonatur(e)} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Partisipan</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className='d-flex justify-content-start align-items-top gap-2'>
                                    <div>
                                        <img src={host + this.state.Gambar} width="310px" height="190px" style={{ borderRadius: "12px" }} />
                                    </div>
                                    <div>
                                        <h3 style={{ fontSize: "20px" }}>{this.state.NamaEvent}</h3>
                                        <table className='table' style={{ lineHeight: "12px" }}>
                                            <tr>
                                                <td style={{ height: "24px", paddingLeft: "0px", fontSize: "24px", }}>Target Donasi</td>
                                                <td style={{ height: "24px", paddingLeft: "0px", fontSize: "24px", }}>:</td>
                                                <td style={{ height: "24px", paddingLeft: "0px", fontSize: "24px", }}>{numberFormat(this.state.TargetDonasi)}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ height: "24px", paddingLeft: "0px" }}>Jumlah Terkumpul</td>
                                                <td style={{ height: "24px", paddingLeft: "0px" }}>:</td>
                                                <td style={{ height: "24px", paddingLeft: "0px" }}>{numberFormat(this.state.JumlahTekumpul)}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ height: "24px", paddingLeft: "0px" }}>Nominal Terpakai</td>
                                                <td style={{ height: "24px", paddingLeft: "0px" }}>:</td>
                                                <td style={{ height: "24px", paddingLeft: "0px" }}>{numberFormat(this.state.NominalTerpakai)}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ height: "24px", paddingLeft: "0px" }}>Batas Hari</td>
                                                <td style={{ height: "24px", paddingLeft: "0px" }}>:</td>
                                                <td style={{ height: "24px", paddingLeft: "0px" }}>{this.state.BatasHari}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <p></p>
                                <div className='row'>
                                    <div className='col-md-9'>
                                        <button className="btn btn-default" onClick={() => rendFormPenarikan("", id)}>+ Tambah</button>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='input-icons'>
                                            <span className='material-icons icon'>search</span>
                                            <input className="form-control login-form" type="search" name="search" placeholder="Search" value={this.state.searchPenarikan} onChange={(e) => this.handleChange(e, "searchPenarikan")} required />
                                        </div>
                                    </div>
                                </div>
                                <p></p>
                                <div className='table-responsive' id="divViewPenarikan"></div>
                                <nav id="pgPenarikan"></nav>
                            </div>
                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <div className='row' style={{ paddingBottom: "24px" }}>
                                    <div className='col-md-6 mb-2'>
                                        <div className="card card-donasi-biru shadow-tipis" style={{ border: "none" }}>
                                            <div className="card-body" style={{ minHeight: "80px", textAlign: "left" }}>
                                                <span className='material-icons' style={{ color: "#57EAE1", backgroundColor: "#DCFFFD", padding: "8px", borderRadius: "8px" }}>account_balance_wallet</span>
                                                <br></br>
                                                <div className='row'>
                                                    <div className='col-md-6 mb-2'>
                                                        <span style={{ fontSize: "20px", fontWeight: "bold" }}>{numberFormat(this.state.target)}</span>
                                                        <br></br>
                                                        <span style={{ fontSize: "14px" }}>Target</span>
                                                    </div>
                                                    <div className='col-md-6 mb-2'>
                                                        <span style={{ fontSize: "20px", fontWeight: "bold" }}>{numberFormat(this.state.terkumpul)}</span>
                                                        <br></br>
                                                        <span style={{ fontSize: "14px" }}>Terkumpul</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        <div className="card card-donasi-biru shadow-tipis" style={{ border: "none" }}>
                                            <div className="card-body" style={{ minHeight: "80px", textAlign: "left" }}>
                                                <span className='material-icons' style={{ color: "#57EAE1", backgroundColor: "#DCFFFD", padding: "8px", borderRadius: "8px" }}>groups</span>
                                                <br></br>
                                                <h1 style={{ fontSize: "24px" }}>{this.state.total}</h1>
                                                <span style={{ fontSize: "14px" }}>Partisipan</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='table-responsive' id="divViewDonatur"></div>
                                <nav id="pgDonatur"></nav>
                            </div>
                        </div>
                    </div>
                </Fragment >
            )
        }
    }
    ReactDOM.render(<FormIni />, document.getElementById('tampil'));
}

async function rendFormPenarikan(ini = "", IDEvent) {
    class FormPenarikan extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                id: ini != "" ? ini.target.dataset.id : "",
                IDEvent: IDEvent,
                TanggalBuat: saiki(),
                Nominal: "0",
                Berita: "",
                act: "penarikat_insert"
            }
        }

        async componentDidMount() {
            ReactDOM.render(<div className='btn-kembali' onClick={() => rendForm(IDEvent)}><spam className="material-icons">arrow_back</spam> Kembali</div>, document.getElementById('divHeaderLabel'));
            if (ini != "") {
                let sql = await api("penarikan_detail", { iddata: this.state.id, token: getCookie("Token") });
                console.log(sql);
                this.setState({
                    TanggalBuat: sql.data[0].TanggalBuat,
                    Nominal: sql.data[0].Nominal,
                    Berita: sql.data[0].Berita ? sql.data[0].Berita.replaceAll("<br>", '\n') : "",
                    act: "penarikan_edit"
                });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        render() {
            return (
                <div className='card-form'>
                    <form className='needs-validation' id="iniForm" onSubmit={(e) => submitForm(e, { act: this.state.act, reload: true })}>
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Update Penarikan</h2>
                        <input type="hidden" name="iddata" value={this.state.id} />
                        <input type="hidden" name="IDEvent" value={this.state.IDEvent} />
                        <input type="hidden" name="act" value={this.state.act} />
                        <input type="hidden" name="token" value={getCookie("Token")} />
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label>Tanggal Buat</label>
                                    <input className="form-control" type="date" name="TanggalBuat" value={this.state.TanggalBuat} onChange={(e) => this.handleChange(e, "TanggalBuat")} required />
                                    <div className='invalid-feedback'>Silahkan masukan tanggal penarikan</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label>Nominal</label>
                                    <input className="form-control" type="number" name="Nominal" value={this.state.Nominal} onChange={(e) => this.handleChange(e, "Nominal")} required />
                                    <div className='invalid-feedback'>Silahkan masukan nominal penarikan</div>
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <label>Deskripsi</label>
                            <textarea name="Berita" className='form-control' value={this.state.Berita} onChange={(e) => this.setState({ Berita: e.target.value })} />
                        </div>
                        <p></p>
                        <button className="btn btn-default" type="submit"><span></span> Simpan</button>
                    </form>
                </div>
            )
        }
    }
    ReactDOM.render(<FormPenarikan />, document.getElementById('tampil'));
}


export default App;